import React from 'react';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';

const FAQs = () => {
  return (
    <main className='faqs-page'>
      <Navbar />

      <header>
        <h2>Frequently Asked Questions</h2>
      </header>

      <section className='content'>
        <h1>Top questions</h1>

        <div className='block'>
          <h3>What do I need to open an account?</h3>
          <p>
            To open an account, you must be at least 16 years old and you must
            have an email address and a phone number.
          </p>
        </div>
        <div className='block'>
          <h3>Is there a minimum account balance?</h3>
          <p>No, we don't have a minimum account balance.</p>
        </div>
        <div className='block'>
          <h3>How Often Can Customers Transfer Funds?</h3>
          <p>As often as possible</p>
        </div>
        <div className='block'>
          <h3>Do you give loans?</h3>
          <p>
            Yes, we give loans, and you'll be eligible to take a loan if you use
            your account regularly.
          </p>
        </div>
        <div className='block'>
          <h3>Can I own multiple assets to diversify my portfolio?</h3>
          <p>
            Absolutely. You can invest in several of our carefully selected
            packages to diversify your portfolio.
          </p>
        </div>
        <div className='block'>
          <h3>How long do crypto withdrawals take?</h3>
          <p>
            When you put in a withdrawal request it takes between 30 - 60
            minutes for the funds to be processed and deposited into your
            account.
          </p>
        </div>
        <div className='block'>
          <h3>What is the deposit limit of my crypto wallet</h3>
          <p>
            There are no upper limits to how much you can deposit at a time.
          </p>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default FAQs;
