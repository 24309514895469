import React from 'react';
import financialFreedom from '../../../assets/financial-freedom.png';
import { useNavigate } from 'react-router-dom';

const FinancialFreedom = () => {
  const navigate = useNavigate();

  return (
    <article className='financial-freedom'>
      <div className='text'>
        <h2>Take a Step Towards Financial Freedom</h2>
        <p>
          Ready to start achieving your financial goals? Open an account today
          and discover the benefits of banking with us all from the comfort of
          your home.
        </p>
        <button className='white' onClick={() => navigate(`/register`)}>
          Open an account today
        </button>
      </div>
      <div className='img'>
        <img src={financialFreedom} alt='' />
      </div>
    </article>
  );
};

export default FinancialFreedom;
