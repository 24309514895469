import React from 'react';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';

const PrivacyPolicies = () => {
  return (
    <main className='privacy-policies-page'>
      <Navbar />
      <section className='content'>
        <h1>Our Privacy Policies</h1>

        <h3>Privacy</h3>
        <p>
          The Elite Financial Bank financial services companies endeavor to
          maintain the highest standards of confidentiality and to respect the
          privacy of our clients and associated persons, our employees, and
          other individuals whose personal information we collect and process.
          Our commitment to privacy includes being transparent about the nature
          and extent of that processing and the rights that may be available to
          you with respect thereto.
        </p>
        <h3>Consents</h3>
        <p>
          You consent to our use of your personal information when you
          (voluntarily) provide us with them to access any of our products
          online or in any of our banking outlets.
        </p>
        <h3>Security of personal information</h3>
        <p>
          Safeguarding your personal information is our main concern. We
          maintain physical, electronic and procedural safeguards that comply
          with applicable federal laws to secure your information from
          unauthorized access and use, accidental or unlawful alteration and
          destruction; and other unlawful or unauthorized forms of processing.
          We engage in the continuous training of our employees in the proper
          management of personal information. When we use other companies to
          provide services, we require them to protect the confidentiality of
          personal information they receive.
        </p>
        <p>
          At Elite Financial Bank, we take data privacy very seriously and we
          provide our customers with all necessary data security to protect such
          personal information from unauthorized access. We require any third
          parties who carry out any work on our behalf to comply with
          appropriate compliance standards to protect your information. We are
          pleased to announce that we comply with global best practices and
          requirements of GDPR of the European Union.
        </p>
        <h3>The information we collect</h3>
        <p>
          We collect your personal information necessary to open a bank account.
          They include name, telephone number, email address. All these required
          that personal data are collected voluntarily and clearly when you
          access our products online or offline.
        </p>
        <h3>How do we collect information?</h3>
        <p>
          We may collect information from a range of sources and it may relate
          to any of our products or services we currently provide or may have
          provided in the past.
        </p>
        <p>We collect your personal information when...</p>
        <ul>
          <li>
            you open an account or perform transactions such as make deposits or
            withdrawals from your account, payment history and transactions
            records
          </li>
          <li>you apply for a loan or use your credit or debit card</li>
          <li>
            you seek advice about your investments you seek information from our
            customer service provider, information concerning complaints and
            disputes
          </li>
          <li>
            we seek information about your credit history from credit bureaus
          </li>
          <li>
            you provide account information such as your personal details e.g.
            name, gender, date and place of birth; contact information such as
            address, email address, and mobile numbers, provide your employment
            information
          </li>
          <li>
            you provide information concerning your identity e.g. photo ID,
            passport information, National ID card and nationality
          </li>
          <li>you use your login credentials for online banking</li>
          <li>
            we conduct necessary investigations i.e., due diligence checks, and
            AML/CFT checks and obtain information that we need to support our
            regulatory obligations, e.g. information about transaction details,
            detection of any suspicious and unusual activities.
          </li>
        </ul>
        <h3>How we use your information</h3>
        <ul>
          <li>
            Elite Financial Bank will only use your information when you have
            provided your consent or when the Bank is required by the law to do
            so.
          </li>
          <li>
            We use the information we collect to provide customers with
            excellent products and services, to manage our business and to offer
            an enriched and enhanced customer experience.
          </li>
          <li>
            We make appropriate use of your data to manage transactions, respond
            to your requests, and to provide you with more relevant products and
            services.
          </li>
          <li>
            We use your information to deliver our products and services, carry
            out your instructions, and provide Online Banking, mobile banking
            and other online product and services.
          </li>
          <li>
            We use these information to detect and prevent financial crimes
            including fraud, financing for terrorism and money laundering, this
            is to ensure security and business continuity.
          </li>
          <li>
            We will use your information to meet our compliance obligations, to
            comply with laws and regulations and to share with regulators when
            absolutely necessary.
          </li>
          <li>
            We may also use personal information we have about you such as your
            email address to deliver advertising to you directly or on our
            websites, provide updates on special deals and offers that might
            interest you (unless you tell us otherwise).
          </li>
          <li>
            We may send you general announcements or important news about your
            account, request your feedback and we believe you should be able to
            select what kinds of information you receive from us via email.
          </li>
          <li>
            We may need to record conversations you have with us including phone
            calls, face-to-face meetings, letters, emails and any other kinds of
            communication. These recordings may be used to check your
            instructions to us and improve on our product and service delivery.
          </li>
        </ul>
        <h3>Who we share your information with</h3>

        <p>
          Towards enhancing our service delivery, we may share data with our
          technical support service providers, partners, associates, and
          subsidiaries in the group. However, we where applicable, we execute
          compliant data sharing agreements or data processing agreements to
          provide an extra layer of protection for personal information shared
          in the process.
        </p>
        <p>Individual rights</p>
        <p>
          The right to be informed <br /> To emphasize the need for transparency
          over the usage of personal data, we ensure fair processing of
          information typically through this privacy policy. The rights to
          access Individuals have the right to access information the Bank
          holds, access their personal data and other supplementary information
          and obtain information about how we process it
        </p>
        <p>
          The rights to access <br /> Individuals have the right to access
          information the Bank holds, access their personal data and other
          supplementary information and obtain information about how we process
          it
        </p>
        <p>
          The right to restrict processing <br /> Individuals have a right to
          'block' or withdraw their consent to our processing of your
          information, which you can do at any time. When processing is
          restricted, we are permitted to store the personal data, but not
          further process it.
        </p>
        <p>
          The right to rectification <br /> Individuals are entitled to have
          personal data rectified if it is inaccurate or incomplete. If this
          personal data in question has been disclosed to third parties, they
          must be informed of the rectification where possible. The Bank must
          also inform the individuals about the third parties to whom the data
          has been disclosed where appropriate
        </p>
        <p>
          The right to erasure <br /> Individuals have the right to request the
          deletion or removal of personal data where there is no compelling
          legal or regulatory requirement for its continued processing. The Bank
          will make sure that this right is protected.
        </p>
        <p>
          The right to data portability <br /> We will ensure that personal data
          is moved, copied or transferred easily from one IT environment to
          another in a safe and secure way, without hindrance to usability.
        </p>
        <p>
          The right to object <br /> Individuals have the right to object to our
          processing of their information in some circumstances.
        </p>
        <h3>Cookies Policy</h3>
        <p>
          A cookie is a small file that is placed on your computer's hard drive.
          Its functions include storing your login and session statuses,
          recording your user preferences and analyzing web traffic.
        </p>
        <p>
          Cookies enable websites and applications to store your user
          preferences in order to enhance your overall website experience by
          better understanding your preferences, likes and dislikes. They also
          allow websites to identify when users are logged on their site and
          when they return to their site, test new content and analyze web
          traffic with data analytics.
        </p>
        <p>
          Apart from the date that you elect to disclose and share with us, we
          cannot access your computer or any other information about you with
          cookies.
        </p>
        <p>
          Although most browsers automatically accept cookies, you can amend
          your browser settings to disable cookies. This may however prevent you
          from fully experiencing the website as it was intended.
        </p>

        <h3>Individual Responsibility</h3>
        <p>
          Individuals are responsible for making sure the information provided
          to the Bank is accurate and should inform the Bank on any changes as
          it occurs, this will enable us to update your information with us.
        </p>

        <h3>Children</h3>
        <p>
          Elite Financial Bank understands and acknowledges the importance of
          safeguarding children's privacy, hence, our sites are not
          intentionally designed for or directed at children under the age of
          13. It is our policy never to knowingly collect or maintain
          information about anyone under the age of 13, except as part of an
          engagement to provide child-categorized account opening services which
          requires parental guardianship.
        </p>

        <h3>Data security and integrity</h3>
        <p>
          Elite Financial Bank has implemented reasonable and effective security
          policies and procedures to protect your personal information from
          unauthorized access, data loss and misuse, alteration, or destruction.
          To the best of our ability, access to your personal information is
          limited to those who have a need to know and are required to always
          maintain the confidentiality of such information. Despite our best
          efforts and intentions, we acknowledge that security cannot be
          absolutely guaranteed against all threat actors. Hence, we also make
          reasonable efforts to retain personal information only for so long
        </p>
        <ul>
          <li>as the data subject asks that the information be deleted</li>
          <li>
            as necessary to comply with legal, regulatory, internal business or
            policy requirements, or
          </li>
          <li>
            the information is necessary to comply with an individual's request.
          </li>
        </ul>
        <p>
          This Privacy Notice may be updated from time to time and the most
          recent version can be found on Elite Financial Bank.
        </p>
      </section>
      <Footer />
    </main>
  );
};

export default PrivacyPolicies;
