import React, { useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import logout from '../../../assets/logout.svg';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalContext } from '../../../context/context';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import spinner from '../../../assets/rolling-spinner-white.svg';
import { StatusModal } from '../../../components/dashboard/modals/Modals';

const Settings = () => {
  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);
  const { baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [msg, setMsg] = useState(``);
  const [status, setStatus] = useState(``);

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const formik = useFormik({
    initialValues: {
      password: ``,
      newPassword: ``,
      confirmPassword: ``,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('Old Password is required'),
      newPassword: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('New Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Input password again'),
    }),
    onSubmit() {
      updatePassword();
    },
  });

  const updatePassword = async () => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        `${baseURL}/auth/v2/update-password
`,
        {
          oldPassword: formik.values.password,
          newPassword: formik.values.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMsg(data.message);
      setStatus(`success`);
      setLoading(false);
      close();
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
      setMsg(error.response.data.error);
      setStatus(`unsuccessful`);
      close();
      setShowStatusModal(true);
    }
  };

  return (
    <main className='settings-page'>
      <Navbar moblieNavText={`Settings`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Settings</h3>
      </div>

      {/* SECURITY SETTINGS */}
      <div className='block security'>
        <h4 className='block-title'>Security Settings</h4>
        <div className='card'>
          <p>Pin</p>
        </div>
        <div className='card'>
          <p onClick={open}>Update Password</p>
        </div>
        <div className='card'>
          <p>Security question</p>
        </div>
      </div>

      {/* LOGOUT */}
      <div className='block'>
        <div
          className='logout'
          onClick={() => {
            sessionStorage.removeItem(`userInfo`);
            navigate(`/`);
          }}
        >
          <img src={logout} alt='logout' />
          <p>Sign out</p>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        title='Update Password'
        centered
        size={`lg`}
      >
        <form action='' onSubmit={formik.handleSubmit}>
          <div className='form-control'>
            <label
              htmlFor='name'
              className={
                formik.touched.password && formik.errors.password ? `error` : ``
              }
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : `Old Password`}
            </label>
            <input
              type='password'
              // id='password'
              name='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='form-control'>
            <label
              htmlFor='phone'
              className={
                formik.touched.newPassword && formik.errors.newPassword
                  ? `error`
                  : ``
              }
            >
              {formik.touched.newPassword && formik.errors.newPassword
                ? formik.errors.newPassword
                : `New Password`}
            </label>
            <div className='password-container'>
              <input
                name='newPassword'
                type='password'
                id='password'
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {showPassword ? (
                <FaEyeSlash
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FaEye
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
          </div>
          <div className='form-control'>
            <label
              htmlFor='phone'
              className={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? `error`
                  : ``
              }
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : `Confirm Password`}
            </label>
            <input
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='btn'>
            <button className='blue' type='submit'>
              Update Password {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </Modal>

      {showStatusModal && (
        <StatusModal
          message={msg}
          status={status}
          dismissFunction={setShowStatusModal}
        />
      )}
    </main>
  );
};

export default Settings;
