import React, { useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import { useGlobalContext } from '../../../context/context';
import axios from 'axios';
import spinner from '../../../assets/rolling-spinner-blue.svg';
import { StatusModal } from '../../../components/dashboard/modals/Modals';

export const Cards = () => {
  return (
    <main className='cards-page'>
      <Navbar currentPage={`cards`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Cards</h3>
      </div>
      <div className='card-types'>
        <Link to='/dashboard/cards/debit-cards' className='card'>
          <h3>Debit Cards</h3>
        </Link>
        <Link to='/dashboard/cards/credit-cards' className='card'>
          <h3>Credit Cards</h3>
        </Link>
      </div>
    </main>
  );
};

export const CreditCards = () => {
  const navigate = useNavigate();
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  const [status, setStatus] = useState(``);

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const requestCard = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/card/add
`,
        {
          cardType: 'Credit',
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setStatus(error.response.status);
      setLoading(false);
      setShowStatusModal(true);
    }
  };

  return (
    <main className='cards-page debit-cards'>
      <Navbar moblieNavText={`Credit Cards`} currentPage={`cards`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/cards`)} />
        <h3>Credit Cards</h3>
      </div>

      <div className='request-card'>
        <h2 onClick={requestCard}>Request new credit card +</h2>
        {loading && <img src={spinner} alt='' />}
      </div>
      {showStatusModal && errorMsg && status === 400 ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={errorMsg}
          status={`unsuccessful`}
        />
      ) : showStatusModal ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={`Your card request was successful and is being processed`}
          navigationUrl={`/dashboard/overview`}
          status={`success`}
        />
      ) : null}
    </main>
  );
};

export const DebitCards = () => {
  const navigate = useNavigate();
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  const [status, setStatus] = useState(``);

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const requestCard = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/card/add
`,
        {
          cardType: 'Debit',
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setStatus(error.response.status);
      setLoading(false);
      setShowStatusModal(true);
    }
  };

  return (
    <main className='cards-page debit-cards'>
      <Navbar moblieNavText={`Debit Cards`} currentPage={`cards`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/cards`)} />
        <h3>Debit Cards</h3>
      </div>

      <div className='request-card'>
        <h2 onClick={requestCard}>Request new card +</h2>
        {loading && <img src={spinner} alt='' />}
      </div>
      {showStatusModal && errorMsg && status === 400 ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={errorMsg}
          status={`unsuccessful`}
        />
      ) : showStatusModal ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={`Your card request was successful and is being processed`}
          navigationUrl={`/dashboard/overview`}
          status={`success`}
        />
      ) : null}
    </main>
  );
};
