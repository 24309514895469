import React from 'react';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';

import encryptionImg from '../../../assets/encryption.svg';
import zeroLiabilityProtectionImg from '../../../assets/zero-liability-protection.svg';
import fraudMonitoringImg from '../../../assets/fraud-monitoring.svg';
import globe from '../../../assets/globe.svg';
import homeHeader from '../../../assets/home-header.webp';

import {
  homeBankingOffers,
  homeCustomerTestimonials,
  homeRightAccounts,
} from '../../../data/regular-data';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  return (
    <main className='home-page'>
      <Navbar />

      {/* HEADER */}
      <header className='header'>
        <div className='info'>
          <h1>Experience the Best in Financial Solutions</h1>
          <p>Secure your finances and maximize your savings potentials.</p>
          <button className='blue' onClick={() => navigate(`/register`)}>
            Open your account
          </button>
        </div>
        <div className='img'>
          <img src={homeHeader} alt='header' />
        </div>
      </header>

      {/* STATS */}
      <section className='stats'>
        <div className='stat'>
          <h2>50+ million</h2>
          <span>accounts</span>
        </div>
        <div className='stat'>
          <h2>2000+</h2>
          <span>US public companies to invest</span>
        </div>
        <div className='stat'>
          <h2>250+ million</h2>
          <span>transactions worldwide</span>
        </div>
        <img src={globe} alt='' />
      </section>

      {/* BANKING OFFERS */}
      <section className='banking-offers'>
        <div className='container'>
          {homeBankingOffers.map((offer, offerIndex) => {
            return (
              <div className={`offer ${offer.extraClass}`} key={offerIndex}>
                <div className='img'>
                  <img src={offer.img} alt={offer.title} />
                </div>
                <div className='info'>
                  <h3>{offer.title}</h3>
                  <p>{offer.info}</p>
                  <button
                    className='transparent'
                    onClick={() => navigate(`/register`)}
                  >
                    Get started
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* THE RIGHT ACCOUNT */}
      <section className='the-right-account'>
        <h2 className='title'>The right account to serve the right purpose</h2>
        <p className='sub-title'>
          With a variety of account options to help you manage your money and
          reach your financial goals. Whether you're looking for a basic
          checking account or a high-yield savings option, we have the right
          account for you.
        </p>

        <div className='cards'>
          <div className='cont'>
            {homeRightAccounts.map((account, accountIndex) => {
              return (
                <div className='card' key={accountIndex}>
                  <div className='img'>
                    <img src={account.img} alt={account.title} />
                  </div>
                  <h3>{account.title}</h3>
                  <p>{account.info}</p>
                  <button
                    className='transparent'
                    onClick={() => navigate(`/register`)}
                  >
                    Open Account
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* OUR TOP PRIORITY */}
      <section className='top-priority'>
        <h2 className='title'>Your Security Is Our Top Priority</h2>
        <p className='sub-title'>
          We understand that keeping your financial information safe and secure
          is of the utmost importance. That's why we have implemented the latest
          security measures to protect your accounts and prevent fraud.
        </p>

        <div className='cards'>
          <div className='card'>
            <div className='img'>
              <img src={encryptionImg} alt='encryption' />
            </div>
            <h3>Encryption</h3>
            <p>
              We use encryption to protect your data as it travels between your
              computer or mobile device and our servers. This ensures that your
              information is kept private and secure.
            </p>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={fraudMonitoringImg} alt='Fraud Monitoring' />
            </div>
            <h3>Fraud Monitoring</h3>
            <p>
              We monitor your accounts for suspicious activity around the clock,
              using advanced fraud detection tools and techniques to keep your
              accounts secure.
            </p>
          </div>
          <div className='card'>
            <div className='img'>
              <img
                src={zeroLiabilityProtectionImg}
                alt='Zero Liability Protection'
              />
            </div>
            <h3>Zero Liability Protection</h3>
            <p>
              In the unlikely event that your account is compromised, we offer
              zero liability protection for fraudulent transactions made with
              your account.
            </p>
          </div>
        </div>
      </section>

      {/* CUSTOMER TESTIMONILAS */}
      <section className='customer-testimonails'>
        <h2 className='title'>Customer Testimonials</h2>
        <p className='sub-title'>
          Hear what our satisfied customers have to say
        </p>

        <div className='cards'>
          <div className='cont'>
            {homeCustomerTestimonials.map((testimony, testimonyIndex) => {
              return (
                <div className='card' key={testimonyIndex}>
                  <div className='customer'>
                    <div className='img'>
                      <img src={testimony.profileImg} alt='customer' />
                    </div>
                    <h4>
                      <span>{testimony.firstName}</span>
                      <span>{testimony.lastName}</span>
                    </h4>
                  </div>
                  <p>{testimony.testimony}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default Home;
