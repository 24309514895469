import React from 'react';
import PageHeader from '../../../components/regular/page-header/PageHeader';
import headerImg from '../../../assets/loans-header.webp';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';
import personalLoanImg from '../../../assets/personal-loan.webp';
import paydayLoanImg from '../../../assets/payday-loan.webp';
import salaryAdvanceLoanImg from '../../../assets/salary-advance-loan.webp';
import vehicleLoanImg from '../../../assets/vehicle-loan.webp';
import homeLoanImg from '../../../assets/home-loan.webp';
import vacationLoanImg from '../../../assets/vacation-loan.webp';

const Loans = () => {
  return (
    <main className='loans-page'>
      <Navbar />
      <PageHeader
        briefInfo={`From financing a wedding to covering unexpected expenses. With fixed interest rates and predictable monthly payments, a personal loan can provide the peace of mind you need to focus on what really matters.`}
        headerImage={headerImg}
        pageTitle={`Loans`}
        btnLink={`/register`}
        btntext={`Get a loan today`}
      />

      {/* LOAN OPTIONS */}
      <section className='loan-options'>
        <h2>Here are just a few of the loan options we offer</h2>

        <div className='cards'>
          <div className='card'>
            <div className='img'>
              <img src={personalLoanImg} alt='personal loan' />
            </div>
            <div className='text'>
              <h4>Personal Loan</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={paydayLoanImg} alt='payday loan' />
            </div>
            <div className='text'>
              <h4>PayDay Loan</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={salaryAdvanceLoanImg} alt='salary advance loan' />
            </div>
            <div className='text'>
              <h4>Salary Advance</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={vehicleLoanImg} alt='vehicle loan' />
            </div>
            <div className='text'>
              <h4>Vehicle Loan</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={homeLoanImg} alt='Home loan' />
            </div>
            <div className='text'>
              <h4>Home Loan</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={vacationLoanImg} alt='Vacation loan' />
            </div>
            <div className='text'>
              <h4>Vacation Loan</h4>
            </div>
          </div>
        </div>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default Loans;
