import React from 'react';
import {
  BsGrid1X2Fill,
  BsFillGearFill,
  BsArrowLeftRight,
} from 'react-icons/bs';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const BottomTab = () => {
  return (
    <nav className='bottom-tab'>
      <Link to='/dashboard/overview' className='item'>
        <BsGrid1X2Fill />
        <p>Overview</p>
      </Link>
      <Link to='/dashboard/transaction-history' className='item'>
        <BsArrowLeftRight />
        <p>Transactions</p>
      </Link>
      <Link to='/dashboard/account' className='item'>
        <FaUser />
        <p>Account</p>
      </Link>
      <Link to='/dashboard/settings' className='item'>
        <BsFillGearFill />
        <p>Settings</p>
      </Link>
    </nav>
  );
};

export default BottomTab;
