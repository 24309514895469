import React from 'react';
import PageHeader from '../../../components/regular/page-header/PageHeader';
import headerImg from '../../../assets/contact-us-header.webp';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';

const ContactUs = () => {
  return (
    <main className='contact-us-page'>
      <Navbar />
      <PageHeader
        briefInfo={`We're here to help you with any questions or concerns you may have about our products and services. Our customer service team is available to assist you in any way possible, and we strive to provide prompt and helpful service to all of our customers. Whether you have a question about your account, need help with an online transaction, or simply want to provide feedback, we're here to help.`}
        headerImage={headerImg}
        pageTitle={`Contact Us`}
      />

      {/* GET IN TOUCH WITH US */}
      <section className='get-in-touch'>
        <div className='title-info'>
          <h3>Get in touch with us</h3>
          <p>
            Please fill out the contact form on this page with your name, email
            address, and a brief message describing your inquiry. Our customer
            service team will get back to you as soon as possible.
          </p>
        </div>
        <form action=''>
          <div className='form-row'>
            <div className='form-control'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' />
            </div>
            <div className='form-control'>
              <label htmlFor='name'>Email</label>
              <input type='text' id='email' name='email' />
            </div>
          </div>
          <div className='form-control'>
            <label htmlFor='name'>Message</label>
            <textarea name='message' id='message'></textarea>
          </div>
          <div className='btn'>
            <button className='blue'>Submit Message</button>
          </div>
        </form>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default ContactUs;
