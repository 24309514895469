import React from 'react';
import PageHeader from '../../../components/regular/page-header/PageHeader';
import headerImg from '../../../assets/about-us-page-header.webp';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';
import { aboutOurGoals } from '../../../data/regular-data';
import ourTeam from '../../../assets/about-us-team.webp';
import ourValues from '../../../assets/about-us-values.webp';
import prevArrow from '../../../assets/prev-arrow-grey.svg';
import nextArrow from '../../../assets/next-arrow-blue.svg';

const AboutUs = () => {
  return (
    <main className='about-us-page'>
      <Navbar />
      <PageHeader
        briefInfo={`We're committed to providing our customers with the best possible banking experience. From our wide range of banking products and services to our commitment to safety and security, we're here to help you achieve your financial goals and build a better financial future.`}
        headerImage={headerImg}
        pageTitle={`About Us`}
      />

      {/* OUR VALUES */}
      <section className='our-values'>
        <h2 className='title'>Our Values</h2>
        <p className='sub-title'>
          Our values are the foundation of everything we do .We believe in
          putting our customers first. That's why we're committed to providing
          the highest level of service and support, whether you're opening a new
          account or applying for a loan. We're also committed to safety and
          security, and we use advanced technology and security measures to
          protect your information and prevent fraud.
        </p>

        <div className='values'>
          <div className='info'>
            <h3>Integrity</h3>
            <p>
              We believe in doing what's right, even when it's not easy. We hold
              ourselves to the highest ethical standards, and we are committed
              to transparency, honesty, and fairness in all our dealings.
            </p>
            <div className='btns'>
              <img src={prevArrow} alt='' />
              <img src={nextArrow} alt='' />
            </div>
          </div>
          <div className='img'>
            <img src={ourValues} alt='our values' />
          </div>
        </div>
      </section>

      {/* OUR MISSION */}
      <section className='our-mission'>
        <h2 className='title'>Our Mission</h2>
        <p className='sub-title'>
          Our mission has always been to empower our customers to take control
          of their finances and build a better financial future. We believe that
          everyone deserves access to high-quality banking services, regardless
          of their financial situation. We're committed to making banking
          simple, accessible, and affordable for everyone, and we're dedicated
          to helping our customers achieve their financial goals.
        </p>
      </section>

      {/* OUR TEAM */}
      <section className='our-team'>
        <h2 className='title'>Our Team</h2>
        <div className='info-img'>
          <div className='info'>
            <p>
              Our team of banking experts is here to help you every step of the
              way. From our knowledgeable customer service representatives to
              our experienced loan officers and investment advisors, we have the
              expertise you need to make informed financial decisions and
              achieve your goals.
            </p>
            <p>
              {' '}
              We're proud of our team's expertise and commitment to customer
              service. We recruit only the best and brightest professionals in
              the banking industry, and we invest heavily in their training and
              development. Our team members are passionate about helping our
              customers achieve their financial goals, and they're always ready
              to go above and beyond to provide the best possible banking
              experience.
            </p>
          </div>
          <div className='img'>
            <img src={ourTeam} alt='our team' />
          </div>
        </div>
      </section>

      {/* OUR GOALS */}
      <section className='our-goals'>
        <h2 className='title'>Our Goals</h2>
        <p className='sub-title'>
          Our goal is to provide our customers with the best possible banking
          experience. We achieve this by focusing on the following goals:
        </p>

        <div className='cards'>
          {aboutOurGoals.map((goal, goalIndex) => {
            return (
              <div className='card' key={goalIndex}>
                <h3>{goal.title}</h3>
                <p>{goal.info}</p>
              </div>
            );
          })}
        </div>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default AboutUs;
