import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageHeader = ({
  briefInfo,
  pageTitle,
  headerImage,
  btntext,
  btnLink,
}) => {
  const navigate = useNavigate();
  return (
    <header className='page-header'>
      <div className='text-info'>
        <h1>{pageTitle}</h1>
        <p>{briefInfo}</p>
        {btntext && btnLink && (
          <button className='white' onClick={() => navigate(btnLink)}>
            {btntext}
          </button>
        )}
      </div>
      <div className='img'>
        <img src={headerImage} alt={pageTitle} />
      </div>
    </header>
  );
};

export default PageHeader;
