import React from 'react';
import hamburger from '../../../assets/hamburger-black.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../context/context';
import companyLogo from '../../../assets/company-logo.svg';

const Navbar = () => {
  const { showRegularNavbar, setShowRegularNavbar } = useGlobalContext();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    setShowRegularNavbar(false);
  };
  return (
    <nav className='regular-navbar'>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='company logo' />
      </Link>
      <div className='hamburger' onClick={() => setShowRegularNavbar(true)}>
        <img src={hamburger} alt='' />
      </div>
      <div className={`links ${showRegularNavbar ? `show` : ``}`}>
        <div className='void' onClick={() => setShowRegularNavbar(false)}></div>
        <div className='cont'>
          <ul>
            <li onClick={handleLinkClick}>
              <Link to='/'>Home</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to='/about-us'>About us</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to='/loans'>Loans</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to='/savings-investments'>Savings & Investment</Link>
            </li>
            <li onClick={handleLinkClick}>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
          </ul>
          <div className='btns'>
            <button
              className='transparent'
              onClick={() => navigate(`/register`)}
            >
              Register
            </button>
            <button className='blue' onClick={() => navigate(`/login`)}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
