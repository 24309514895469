import React from 'react';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';

const TermsConditions = () => {
  return (
    <main className='terms-conditions-page'>
      <Navbar />
      <section className='content'>
        <h1>Terms & Conditions</h1>
        <p>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING THIS
          WEBSITE YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS BELOW. THESE
          TERMS AND CONDITIONS ARE SUBJECT TO CHANGE. ANY CHANGES WILL BE
          INCORPORATED INTO THE TERMS AND CONDITIONS POSTED TO THIS WEBSITE FROM
          TIME TO TIME. IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS,
          PLEASE DO NOT ACCESS THIS WEBSITE.
        </p>
        <p>
          Unauthorized use of Elite Financial Bank's Websites and systems,
          including but not limited to unauthorized entry into Elite Financial
          Bank's systems, misuse of passwords, posting of objectionable or
          offensive content or your unauthorized use of legally protected third
          party content, or misuse of any information posted to a site, is
          strictly prohibited. You acknowledge that Elite Financial Bank may
          disclose and transfer any information that you provide through this
          Website to (i) any company within the Elite Financial Bank group, its
          affiliates agents or information providers; (ii) to any other person
          or entity with your consent; or (iii) if we have a right or duty to
          disclose or are permitted or compelled to so disclose such information
          by law. You consent to the transmission, transfer or processing of
          such information to, or through, any country in the world, as we deem
          necessary or appropriate (including to countries outside the EEA), and
          by using and providing information through this Website you agree to
          such transfers. Use of this Website, including any patterns or
          characteristics concerning your interaction with it, may be monitored,
          tracked and recorded. Anyone using this Website expressly consents to
          such monitoring, tracking and recording. You agree not to attempt to
          log on to the Website from any country under sanctions by the Office
          of Foreign Assets Control (OFAC). Information regarding which
          countries are under sanctions may be obtained on the U.S. Department
          of the Treasury website. Any attempt to log on to the Website from one
          of these countries may result in your access being restricted and/or
          terminated. If you use Elite Financial Bank's Website or systems to
          access data related to any account(s) of which you are not the owner
          or authorized user as reflected in Elite Financial Bank's systems, you
          shall indemnify, defend, and hold harmless Elite Financial Bank & Co.
          and all of its direct and indirect subsidiaries, officers, directors,
          employees, agents, successors, and assigns from any and all losses,
          liabilities, damages, and all related costs and expenses, arising
          from, relating to, or resulting (directly or indirectly) from such
          access. Further, without limiting Elite Financial Bank's rights or
          your obligations under any other provision of these Terms and
          Conditions, and notwithstanding the same, in the event of any actual
          or reasonably suspected unauthorized access to the personal
          information of a customer (including but not limited to customer
          names, addresses, phone numbers, bank and credit card account numbers,
          income and credit histories, and social security numbers) under your
          control or subsequent to and arising from your past exercise of
          control, direct damages in connection with any such breach will
          include the cost and expenses of investigation and analysis (including
          by law firms and forensic firms), correction or restoration of any
          destroyed, lost or altered data, notification to affected customers,
          offering and providing of credit monitoring, customers service, or
          other remediation services, and any related cost. Elite Financial Bank
          & Co.'s rights to indemnity under this section are in addition to all
          other rights and remedies available at Law or in equity. Any exercise
          by Elite Financial Bank & Co. of its rights to indemnification shall
          be without prejudice to such other rights and remedies. You manifest
          your assent to this indemnity by accessing account data through Elite
          Financial Bank's Website or systems, notwithstanding the terms of any
          agreement you have with a customer or an account owner stating
          otherwise. This indemnity includes but is not limited to losses
          associated with (1) a data breach of your system(s) and (2) a data
          breach of the system(s) of any person or entity with whom you provided
          or shared Elite Financial Bank customer account data.
        </p>
        <h3>Copyright Notices</h3>
        <p>
          The works of authorship contained in the elitefinancialhub.com Website
          (the "Website"), including but not limited to all design, text, sound
          recordings and images, are owned, except as otherwise expressly
          stated, by Elite Financial Bank & Co. Except as otherwise expressly
          stated herein, they may not be copied, transmitted, displayed,
          performed, distributed (for compensation or otherwise), licensed,
          altered, framed, stored for subsequent use or otherwise used in whole
          or in part in any manner without Elite Financial Bank's prior written
          consent. Only with notices of Elite Financial Bank's proprietary
          rights provided that you may download information and print out hard
          copies for your personal use, so long as you do not remove any
          copyright or other notice as may be contained in information, as
          downloaded.
        </p>
        <h3>Trademark Notices</h3>
        <p>
          EFB is the marketing name for the retail financial services activities
          of Financial Bank. and its subsidiaries and affiliates in the United
          States. "EFB," the EFB logo are trademarks of Elite Financial Bank.
        </p>
        <h3>Web Content and Materials</h3>
        <p>
          The information on this Website is for information purposes only. It
          is believed to be reliable, but Elite Financial Bank does not warrant
          its completeness, timeliness or accuracy.
        </p>
        <p>
          The information and materials contained in this Website-and the terms
          and conditions of the access to and use of such information and
          materials-are subject to change without notice. Products and services
          described, as well as, associated fees, charges, interest rates, and
          balance requirements may differ among geographic locations. Not all
          products and services are offered at all locations.
        </p>
        <p>
          Certain portions or pages of this Website are subject to additional
          disclosures and disclaimers. In the event of a conflict between those
          disclosures and disclaimers, and these terms and conditions, the
          additional disclosures and disclaimers will govern for those portions
          or pages.
        </p>
        <p>
          You agree that (i) you will not engage in any activities related to
          the Website that are contrary to applicable law, regulation or the
          terms of any agreements you may have with Elite Financial Bank, and
          (ii) in circumstances where locations of the Website require
          identification for process, you will establish commercially reasonable
          security procedures and controls to limit access to your password or
          other identifying information to authorized individuals.
        </p>
        <p>
          ELITE FINANCIAL BANK OR ITS SUPPLIERS MAY DISCONTINUE OR MAKE CHANGES
          IN THE INFORMATION, PRODUCTS OR SERVICES DESCRIBED HEREIN AT ANY TIME
          WITHOUT PRIOR NOTICE TO YOU AND WITHOUT ANY LIABILITY TO YOU. ANY
          DATED INFORMATION IS PUBLISHED AS OF ITS DATE ONLY, AND Elite
          Financial Bank DOES NOT UNDERTAKE ANY OBLIGATION OR RESPONSIBILITY TO
          UPDATE OR AMEND ANY SUCH INFORMATION. ELITE FINANCIAL BANK RESERVES
          THE RIGHT TO TERMINATE ANY OR ALL WEBSITE OFFERINGS OR TRANSMISSIONS
          WITHOUT PRIOR NOTICE TO THE USER. FURTHERMORE, BY OFFERING THIS
          WEBSITE AND INFORMATION, PRODUCTS OR SERVICES VIA THIS WEBSITE, NO
          DISTRIBUTION OR SOLICITATION IS MADE BY ELITE FINANCIAL BANK TO ANY
          PERSON TO USE THE WEBSITE OR SUCH INFORMATION, PRODUCTS OR SERVICES IN
          JURISDICTIONS WHERE THE PROVISION OF THE WEBSITE AND SUCH INFORMATION,
          PRODUCTS OR SERVICES IS PROHIBITED BY LAW.
        </p>
        <h3>Potential Disruption of Service</h3>
        <p>
          Access to the Website may from time to time be unavailable, delayed,
          limited or slowed due to, among other things:
        </p>
        <ul>
          <li>
            Hardware failure, including among other things failures of computers
            (including your own computer), servers, networks, telecommunication
            lines and connections, and other electronic and mechanical equipment
          </li>
          <li>
            Software failure, including among other things, bugs, errors,
            viruses, configuration problems, incompatibility of systems,
            utilities or applications, the operation of firewalls or screening
            programs, unreadable codes, or irregularities within particular
            documents or other content
          </li>
          <li>Overload of system capacities</li>
          <li>
            Damage caused by severe weather, earthquakes, wars, insurrection,
            riots, civil commotion, act of God, accident, fire, water damage,
            explosion, mechanical breakdown or natural disasters
          </li>
          <li>
            Interruption (whether partial or total) of power supplies or other
            utility of service
          </li>
          <li>
            strike or other stoppage (whether partial or total) of labor
            Governmental or regulatory restrictions, exchange rulings, court or
            tribunal orders or other human intervention
          </li>
          <li>
            Any other cause (whether similar or dissimilar to any of the
            foregoing) whatsoever beyond the control of Elite Financial Bank
          </li>
        </ul>
        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          BECAUSE OF THE POSSIBILITY OF HUMAN AND MECHANICAL ERROR AS WELL AS
          OTHER FACTORS, THE WEBSITE (INCLUDING ALL INFORMATION AND MATERIALS
          CONTAINED ON THE WEBSITE) IS PROVIDED "AS IS" "AS AVAILABLE". ELITE
          FINANCIAL BANK AND THIRD PARTY DATA PROVIDERS ARE NOT PROVIDING ANY
          WARRANTIES AND REPRESENTATIONS REGARDING THE WEBSITE. ELITE FINANCIAL
          BANK AND THIRD PARTY DATA PROVIDERS DISCLAIM ALL WARRANTIES AND
          REPRESENTATIONS OF ANY KIND WITH REGARD TO THE WEBSITE, INCLUDING ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY
          RIGHTS, FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, OR FITNESS FOR ANY
          PARTICULAR PURPOSE. FURTHER, ELITE FINANCIAL BANK WILL NOT BE LIABLE
          FOR ANY DELAY, DIFFICULTY IN USE, INACCURACY OF INFORMATION, COMPUTER
          VIRUSES, MALICIOUS CODE OR OTHER DEFECT IN THIS WEBSITE, OR FOR THE
          INCOMPATIBILITY BETWEEN THIS WEBSITE AND FILES AND THE USER'S BROWSER
          OR OTHER SITE ACCESSING PROGRAM. NOR WILL ELITE FINANCIAL BANK BE
          LIABLE FOR ANY OTHER PROBLEMS EXPERIENCED BY THE USER DUE TO CAUSES
          BEYOND ELITE FINANCIAL BANK'S CONTROL. NO LICENSE TO THE USER IS
          IMPLIED IN THESE DISCLAIMERS. ELITE FINANCIAL BANK AND THIRD PARTY
          DATA PROVIDERS DO NOT WARRANT THE ACCURACY, ADEQUACY, OR COMPLETENESS
          OF THE INFORMATION AND MATERIALS CONTAINED ON THE WEBSITE AND
          EXPRESSLY DISCLAIMS LIABILITY FOR ERRORS OR OMISSIONS IN THE MATERIALS
          AND INFORMATION. FURTHERMORE, ELITE FINANCIAL BANK AND ITS AFFILIATES
          WILL NOT BE LIABLE FOR ANY DELAY, DIFFICULTY IN USE, COMPUTER VIRUSES,
          MALICIOUS CODE, OR OTHER DEFECT IN WEBSITE, ANY INCOMPATIBILITY
          BETWEEN THE WEBSITE AND THE USER'S FILES AND THE USER'S BROWSER OR
          OTHER SITE ACCESSING PROGRAM, OR ANY OTHER PROBLEMS EXPERIENCED BY THE
          USER DUE TO CAUSES BEYOND ELITE FINANCIAL BANK AND ITS AFFILIATES'
          CONTROL. NO LICENSE TO THE USER IS IMPLIED IN THESE DISCLAIMERS.
          NOTHING HEREIN SHALL BE CONSTRUED AS LIMITING OR REDUCING ELITE
          FINANCIAL BANK'S RESPONSIBILITIES AND OBLIGATIONS TO CLIENTS IN
          ACCORDANCE WITH APPLICABLE LAWS AND REGULATIONS. UNDER NO
          CIRCUMSTANCES WILL ELITE FINANCIAL BANK BE LIABLE FOR ANY LOST
          PROFITS, LOST OPPORTUNITY OR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL,
          SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF ANY USE OF OR
          INABILITY TO USE THE WEBSITE OR ANY PORTION THEREOF, REGARDLESS OF
          WHETHER ELITE FINANCIAL BANK HAS BEEN APPRISED OF THE LIKELIHOOD OF
          SUCH DAMAGES OCCURRING AND REGARDLESS OF THE FORM OF ACTION, WHETHER
          IN CONTRACT, WARRANTY, TORT, (INCLUDING NEGLIGENCE), STRICT LIABILITY,
          OR OTHERWISE.
        </p>
      </section>
      <Footer />
    </main>
  );
};

export default TermsConditions;
