import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import bin from '../../../assets/bin.svg';
import spinner from '../../../assets/rolling-spinner-blue.svg';
import moment from 'moment/moment';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IoMdMail } from 'react-icons/io';
import { IoMailOpen } from 'react-icons/io5';

const Notifications = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [notificaions, setNotifications] = useState([]);
  const [singelNotification, setSingelNotification] = useState({});
  const [opened, { open, close }] = useDisclosure(false);

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const getNotifications = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${baseURL}/notification/all
`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotifications(data.allNotifications);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getSpecificNotification = async (id) => {
    try {
      const { data } = await axios.get(
        `${baseURL}/notification/single/${id}
`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSingelNotification(data);
      getNotifications();
    } catch (error) {}
  };

  const deleteNotifications = async (id) => {
    try {
      const { data } = await axios.delete(
        `${baseURL}/notification/single/${id}
`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotifications(data.allNotifications);
      getNotifications();
    } catch (error) {}
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, []);

  return (
    <main className='notifications-page'>
      <Navbar moblieNavText={`Notifications`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Notifications</h3>
      </div>

      {loading ? (
        <div className='loader'>
          <img src={spinner} alt='' />
        </div>
      ) : notificaions.length < 1 ? (
        <div className='no-notifications'>
          <h1>No Notifications Yet</h1>
        </div>
      ) : (
        <div className='cards-container'>
          {notificaions.map((notif, notifIndex) => {
            const { _id, title, body, createdAt, read } = notif;
            return (
              <div className='notif-card' key={notifIndex}>
                <div className='title-time'>
                  <h4>{title}</h4>
                  <h5>
                    <span>{moment(createdAt).format(`L`)}</span>
                    <span>{moment(createdAt).format(`LT`)}</span>
                  </h5>
                </div>
                <p>{body}</p>
                <div className='btns'>
                  {read ? (
                    <IoMailOpen
                      className='mail'
                      onClick={() => {
                        getSpecificNotification(_id);
                        open();
                      }}
                    />
                  ) : (
                    <IoMdMail
                      className='mail'
                      onClick={() => {
                        getSpecificNotification(_id);
                        open();
                      }}
                    />
                  )}
                  <div
                    className='delete'
                    onClick={() => deleteNotifications(_id)}
                  >
                    <img src={bin} alt='delete bin' />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {singelNotification && (
        <Modal
          opened={opened}
          onClose={close}
          title={singelNotification.title}
          centered
          size={`lg`}
          className='notification-modal'
        >
          <p>{singelNotification.body}</p>
        </Modal>
      )}
    </main>
  );
};

export default Notifications;
