import testimonialCustomer1Img from '../assets/testimonial-customer-1.webp';
import testimonialCustomer2Img from '../assets/testimonial-customer-2.jpeg';
import testimonialCustomer3Img from '../assets/testimonial-customer-3.jpeg';

import checkingAccount from '../assets/checking-account.svg';
import savingsAccount from '../assets/savings-account.svg';
import businessAccount from '../assets/business-account.svg';
import irAccount from '../assets/iras.svg';

import bankingOfferSavings from '../assets/home-offers-savings.svg';
import bankingOfferInvestments from '../assets/home-offers-investments.svg';
import bankingOfferCreditCard from '../assets/home-offers-credit-card.svg';
import bankingOfferLoans from '../assets/home-offers-loans.svg';

export const securityInfo = [
  {
    title: `Encryption`,
    info: `We use encryption technology to secure our customers' online and mobile banking transactions. This ensures that any data exchanged between our customers and our servers is protected from unauthorized access.`,
  },
  {
    title: `Two-Factor Authentication`,
    info: `We require two-factor authentication for certain transactions, such as transferring funds or changing personal information. This means that our customers must provide two forms of identification, such as a password and a security code, to complete the transaction.`,
  },
  {
    title: `Fraud Monitoring`,
    info: `We monitor our customers' accounts for suspicious activity and alert them immediately if we detect any unusual activity. Our fraud monitoring system is designed to detect and prevent fraudulent activity before it becomes a problem.`,
  },
  {
    title: `Firewalls`,
    info: `We use firewalls to prevent unauthorized access to our customers' information. Our firewalls block unauthorized attempts to access our systems and prevent malicious software from infecting our servers.`,
  },
  {
    title: `Employee Training`,
    info: `We provide regular training to our employees to ensure they are aware of the latest security threats and best practices. This helps to minimize the risk of human error and ensures that our employees are equipped to handle security threats.`,
  },
  {
    title: `Regular Audits`,
    info: `We regularly audit our security measures and protocols to ensure they are up-to-date and effective. This helps us to identify potential vulnerabilities and make improvements where necessary.`,
  },
];

export const securityTips = [
  {
    title: `1. Protect your passwords:`,
    info: `Always use strong and unique passwords for your accounts, and avoid using the same password for multiple accounts. Change your passwords regularly, and never share them with anyone.`,
  },
  {
    title: `2. Monitor your accounts:`,
    info: `Keep a close eye on your bank and credit card statements, and report any suspicious activity to your bank immediately. Reviewing your accounts regularly can help you catch fraudulent activity before it becomes a bigger problem.`,
  },
  {
    title: `3. Be cautious of phishing scams:`,
    info: `Scammers often use email or text messages to trick people into giving them their personal information. Be wary of any unsolicited messages asking for your personal information, and never click on links or download attachments from unknown senders.`,
  },
  {
    title: `4. Keep your software up-to-date:`,
    info: `Ensure that your computer, smartphone, and other devices are running the latest software updates and security patches. This can help protect you from security vulnerabilities that could be exploited by hackers.`,
  },
  {
    title: `5. Use secure networks:`,
    info: `Avoid using public Wi-Fi to access your financial accounts, as these networks are often unsecured and can be easily accessed by hackers. If you need to access your accounts while on the go, use a secure virtual private network (VPN) to protect your data.`,
  },
  {
    title: `6. Safeguard your physical documents:`,
    info: `Keep important documents, such as your social security card and bank statements, in a safe and secure location. Shred any documents that contain sensitive information before disposing of them.`,
  },
];

export const aboutOurGoals = [
  {
    title: `Safety and security`,
    info: `We use advanced technology and security measures to protect our customers' information and prevent fraud. Our customers can feel confident that their funds and personal information are safe and secure with us.`,
  },
  {
    title: `Accessibility`,
    info: `We believe that everyone deserves access to high-quality banking services, regardless of their financial situation. That's why we offer a wide range of banking products and services that are accessible and affordable to everyone.`,
  },
  {
    title: `Convenience`,
    info: `We understand that our customers are busy, so we offer a range of online and mobile banking services that make it easy and convenient to manage their finances on the go.`,
  },
  {
    title: `Expertise`,
    info: `Our team of banking experts has the knowledge and expertise to help our customers make informed financial decisions and achieve their goals. We offer a range of financial planning and investment services to help our customers build a better financial future.`,
  },
];

export const homeCustomerTestimonials = [
  {
    firstName: `Abraham`,
    lastName: `Benjamin`,
    testimony: `I've been using this bank's online platform for years now, and it's been a game-changer. I can easily access my account information, make transfers, and pay bills all from the comfort of my own home. The website is intuitive and user-friendly, and I highly recommend it to anyone looking for a reliable online banking experience.`,
    profileImg: testimonialCustomer1Img,
  },
  {
    firstName: `Kelvin`,
    lastName: `David`,
    testimony: `As a small business owner, I rely on this bank's website to manage my finances efficiently. The website allows me to easily view my account balances, track transactions, and schedule payments. The customer service team is also incredibly responsive and helpful, making the overall banking experience a breeze.`,
    profileImg: testimonialCustomer2Img,
  },
  {
    firstName: `Sarah `,
    lastName: `Maclean`,
    testimony: `I've been a loyal customer of this bank for years, and their website has only made my experience better. The website is constantly updated with new features and functionalities, making it easier than ever to manage my finances. Whether I need to transfer funds or deposit a check, I can do it all from the convenience of my own computer.`,
    profileImg: testimonialCustomer3Img,
  },
];

export const homeRightAccounts = [
  {
    title: `Checking Account`,
    info: `A checking account offers easy access to your money for your daily transactional needs and helps keep your cash secure.`,
    img: checkingAccount,
  },
  {
    title: `Savings Account`,
    info: `A savings account is a bank account at a retail bank. Common features include a limited number of withdrawals, a lack of cheque and linked debit card facilities, limited transfer options and the inability to be overdrawn. `,
    img: savingsAccount,
  },
  {
    title: `Business Account`,
    info: `A business bank account is a bank account that's used only for business transactions rather than personal finances. It can be opened in the name of the business, allowing payments to be made and received using the business's name.`,
    img: businessAccount,
  },
  {
    title: `IRAs`,
    info: `A business bank account is a bank account that's used only for business transactions rather than personal finances. It can be opened in the name of the business, allowing payments to be made and received using the business's name.`,
    img: irAccount,
  },
];

export const homeBankingOffers = [
  {
    title: `Savings`,
    info: `We offer a range of savings accounts to help you achieve your financial goals. Whether you're saving for a rainy day or planning for a big purchase, we have the right account for you. With competitive interest rates and convenient online access, saving has never been easier. Open an account today and start watching your money grow.`,
    img: bankingOfferSavings,
    extraClass: ``,
  },
  {
    title: `Investments`,
    info: `We believe that investing is key to building long-term wealth. That's why we offer a range of investment options to help you achieve your financial goals. From retirement accounts to stocks and bonds, our financial experts can help you make informed investment decisions and grow your wealth over time. Start investing in your future today with Elite Financial Bank.`,
    img: bankingOfferInvestments,
    extraClass: `reverse`,
  },
  {
    title: `Credit card`,
    info: `With our credit card, you have the power to make purchases whenever and wherever you need to. Our credit cards offer a range of features and benefits to help you manage your spending and build your credit. From cash back rewards to travel points, our credit cards have the rewards you want and the flexibility you need.`,
    img: bankingOfferCreditCard,
    extraClass: ``,
  },
  {
    title: `Loans`,
    info: `Whether you're buying a home, starting a business, or just need some extra cash, we have the loan options you need to get the financing you need. From personal loans to mortgages, our loan experts can help you find the right option for your financial needs. With competitive rates and flexible repayment options, our loans make it easy to achieve your goals and stay on track financially.`,
    img: bankingOfferLoans,
    extraClass: `reverse`,
  },
];
