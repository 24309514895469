import React from 'react';
import PageHeader from '../../../components/regular/page-header/PageHeader';
import headerImg from '../../../assets/security-page-header.webp';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';
import { securityInfo, securityTips } from '../../../data/regular-data';

const Security = () => {
  return (
    <main className='security-page'>
      <Navbar />
      <PageHeader
        briefInfo={`We take the security of our customers' information very seriously. We understand that our customers trust us with their personal and financial information, and we are committed to protecting that information with advanced security measures and protocols.`}
        headerImage={headerImg}
        pageTitle={`Security`}
      />

      {/* SECURITY INFO */}
      <section className='security-info'>
        <h2 className='title'>Our Security Measures</h2>
        <p className='sub-title'>
          Here are some of the security measures and steps we take to keep our
          customers' information safe:
        </p>

        {securityInfo.map((block, blockIndex) => {
          return (
            <div className='block' key={blockIndex}>
              <h3>{block.title}</h3>
              <p>{block.info}</p>
            </div>
          );
        })}

        <p className='sub-title'>
          We believe in keeping your finances safe and secure, we also believe
          your safety requires equal effort, that is why we put together a list
          of steps you can take to ensure your finances are safe.
        </p>
        <h2 className='title' style={{ marginBottom: `2rem` }}>
          Tips to Secure Your Financial Information
        </h2>

        {securityTips.map((block, blockIndex) => {
          return (
            <div className='block' key={blockIndex}>
              <h3>{block.title}</h3>
              <p>{block.info}</p>
            </div>
          );
        })}

        <h2 className='title'>Remeber</h2>
        <p className='sub-title'>
          By following these tips, you can help keep your finances and financial
          information safe. We take the security of our customers' information
          very seriously, and we are always here to help if you have any
          questions or concerns.
        </p>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default Security;
