import React from 'react';
import PageHeader from '../../../components/regular/page-header/PageHeader';
import headerImg from '../../../assets/savings-investments-header.webp';
import FinancialFreedom from '../../../components/regular/financial-freedom/FinancialFreedom';
import Footer from '../../../components/regular/footer/Footer';
import Navbar from '../../../components/regular/navbar/Navbar';
import basicSA from '../../../assets/basic-savings-account.webp';
import highYieldSA from '../../../assets/high-yield-savings-account.webp';
import moneyMarketA from '../../../assets/money-market-account.webp';
import ira from '../../../assets/ira.webp';
import kidsSA from '../../../assets/kids-savings-account.webp';
import certificateOfDeposit from '../../../assets/cerficate-of-deposit.webp';
import mutualFunds from '../../../assets/mutual-funds.webp';
import stocks from '../../../assets/stocks.webp';
import bonds from '../../../assets/bonds.webp';
import realEstate from '../../../assets/real-estate-investment-trusts.webp';
import { useNavigate } from 'react-router-dom';

const SavingsInvestments = () => {
  const navigate = useNavigate();

  return (
    <main className='savings-investments-page'>
      <Navbar />
      <PageHeader
        briefInfo={`With our range of savings and investment options, we offer a variety of ways for you to grow your wealth and achieve your financial goals. Our savings accounts offer competitive interest rates, no fees, and easy access to your money whenever you need it. And with our investment options, you can invest in a range of assets, with the guidance of our expert advisors.`}
        headerImage={headerImg}
        pageTitle={`Savings & Investment`}
        btnLink={`/register`}
        btntext={`Open a savings account`}
      />

      {/* GROW YOUR SAVINGS */}
      <section className='grow-savings'>
        <h2 className='title'>Secure and Grow Your Savings</h2>
        <p className='sub-title'>
          We understand the importance of saving money and building a solid
          financial foundation, which is why we offer a variety of savings
          account options to help you reach your financial goals. Whether you're
          saving for a down payment on a house, a dream vacation, or simply a
          rainy day, we've got you covered. Our savings accounts offer
          competitive interest rates and flexible terms, so you can choose the
          account that best suits your needs.
        </p>

        <div className='cards'>
          <div className='card'>
            <div className='img'>
              <img src={basicSA} alt='basic savings account' />
            </div>
            <div className='text'>
              <h4>Basic Savings Account</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={highYieldSA} alt='high yield savings account' />
            </div>
            <div className='text'>
              <h4>High-Yield Savings Account</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={moneyMarketA} alt='money market account' />
            </div>
            <div className='text'>
              <h4>Money Market Account</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={ira} alt='individual retirement account' />
            </div>
            <div className='text'>
              <h4>Individual Retirement Account (IRA)</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={kidsSA} alt='kids savings account' />
            </div>
            <div className='text'>
              <h4>Kids' Savings Account</h4>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={certificateOfDeposit} alt='certificate of deposit' />
            </div>
            <div className='text'>
              <h4>Certificate of Deposit (CD)</h4>
            </div>
          </div>
        </div>
      </section>

      {/* TOP TIER INVESTMENTS */}
      <section className='top-tier-investments'>
        <h2 className='title'>Top Tier Investments Available To You</h2>
        <p className='sub-title'>
          Investing your money can be a powerful way to grow your wealth and
          achieve your financial goals. Whether you're saving for retirement, a
          child's education, or simply looking to build your portfolio, we offer
          a range of investment options to help you achieve your goals. Our
          investment products are designed to help you build a diversified
          portfolio tailored to your risk tolerance and investment objectives.
          With our expert guidance and resources, you can make informed
          investment decisions and build a stronger financial future.
        </p>

        <div className='cards'>
          <div className='card'>
            <div className='img'>
              <img src={mutualFunds} alt='mutual funds' />
            </div>
            <div className='text'>
              <h3>Mutual Funds</h3>
              <p>
                Professionally managed portfolios of stocks, bonds, and other
                securities that offer diversification and potential for growth.
                We offer a wide range of mutual funds to suit your investment
                goals and risk tolerance.
              </p>
              <button className='blue' onClick={() => navigate(`/login`)}>
                Sign in
              </button>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={stocks} alt='stocks' />
            </div>
            <div className='text'>
              <h3>Stocks</h3>
              <p>
                Stocks represent ownership in a company and offer the potential
                for long-term growth. We offer a range of stocks from
                established companies with a track record of success to emerging
                companies with high growth potential.
              </p>
              <button className='blue' onClick={() => navigate(`/login`)}>
                Sign in
              </button>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={bonds} alt='bonds' />
            </div>
            <div className='text'>
              <h3>Bonds</h3>
              <p>
                Bonds are debt securities issued by governments or companies and
                offer a fixed rate of return. We offer a range of bonds with
                varying maturities and credit ratings to suit your investment
                objectives.
              </p>
              <button className='blue' onClick={() => navigate(`/login`)}>
                Sign in
              </button>
            </div>
          </div>
          <div className='card'>
            <div className='img'>
              <img src={realEstate} alt='real estate investment trusts' />
            </div>
            <div className='text'>
              <h3>Real Estate Investment Trusts (REITs)</h3>
              <p>
                REITs are companies that own and operate income-producing real
                estate properties. Investing in REITs can provide exposure to
                the real estate market without the hassle of property
                management.
              </p>
              <button className='blue' onClick={() => navigate(`/login`)}>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </section>

      <FinancialFreedom />
      <Footer />
    </main>
  );
};

export default SavingsInvestments;
