import axios from 'axios';
import React, { useContext, useState } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.elitefinancialhub.org`;
  // const baseURL = `https://bank-one.onrender.com`;
  const [showDashboardNavbar, setShowDashboardNavbar] = useState(false);
  const [showRegularNavbar, setShowRegularNavbar] = useState(false);
  const [showDepostiModal, setShowDepositModal] = useState(false);

  // SHOW PASSWORD
  const revealPassword = (id) => {
    const passwordInput = document.querySelector(`#${id}`);
    if (passwordInput.type === `password`) {
      passwordInput.type = `text`;
    } else {
      passwordInput.type = `password`;
    }
  };

  // FETCH UPDATED USER DETAILS
  const fetchUpdateUserDetails = async (token) => {
    try {
      const { data } = await axios.get(`${baseURL}/tokenized-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.setItem(`userInfo`, JSON.stringify(data));
    } catch (error) {}
  };

  // WAKE SERVER
  const wakeServer = async () => {
    try {
      await axios.get(baseURL);
    } catch (error) {}
  };

  return (
    <AppContext.Provider
      value={{
        baseURL,
        revealPassword,
        showDashboardNavbar,
        setShowDashboardNavbar,
        showRegularNavbar,
        setShowRegularNavbar,
        showDepostiModal,
        setShowDepositModal,
        wakeServer,
        fetchUpdateUserDetails,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
