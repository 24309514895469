import React from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import supportImg from '../../../assets/support.jpg';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';

export const Deposit = () => {
  return (
    <main className='deposit-page'>
      <Navbar currentPage={`deposit`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Deposit</h3>
      </div>
      <div className='methods'>
        <Link to='/dashboard/deposit/bank-deposit' className='card'>
          <h3>Bank deposit</h3>
        </Link>
        <Link to='/dashboard/deposit/crypto' className='card'>
          <h3>Crypto deposit</h3>
        </Link>
        <Link to='/dashboard/deposit/paypal-deposit' className='card'>
          <h3>Paypal deposit</h3>
        </Link>
      </div>
    </main>
  );
};

export const CryptoDeposit = () => {
  const navigate = useNavigate();

  return (
    <main className='deposit-page crypto'>
      <Navbar moblieNavText={`Crypto Deposit`} currentPage={`deposit`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/deposit`)} />
        <h3>Crypto Deposit</h3>
      </div>
      <ContactSupport />
    </main>
  );
};

export const BankDeposit = () => {
  const navigate = useNavigate();

  return (
    <main className='deposit-page crypto'>
      <Navbar moblieNavText={`Crypto Deposit`} currentPage={`deposit`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/deposit`)} />
        <h3>Bank Deposit</h3>
      </div>
      <ContactSupport />
    </main>
  );
};

export const PaypalDeposit = () => {
  const navigate = useNavigate();

  return (
    <main className='deposit-page crypto'>
      <Navbar moblieNavText={`Crypto Deposit`} currentPage={`deposit`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/deposit`)} />
        <h3>Paypal Deposit</h3>
      </div>
      <ContactSupport />
    </main>
  );
};

const ContactSupport = () => {
  return (
    <section className='contact-support'>
      <div className='img'>
        <img src={supportImg} alt='' />
      </div>
      <h1>Please contact support to continue with this transaction</h1>
    </section>
  );
};
