import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import creditTransaction from '../../../assets/credit-transaction.svg';
import debitTransaction from '../../../assets/debit-transaction.svg';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import spinner from '../../../assets/rolling-spinner-blue.svg';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';

const TransactionHistory = () => {
  const [currentTab, setCurrentTab] = useState(`deposits`);

  const handleTabClick = (e) => {
    setCurrentTab(e.target.textContent.toLowerCase());
  };

  return (
    <main className='transaction-history-page'>
      <Navbar
        moblieNavText={`Transaction History`}
        currentPage={`transactions`}
      />
      <BottomTab />

      <div className='page-title'>
        <h3>Transaction History</h3>
      </div>

      <div className='tabs'>
        <ul>
          <li
            className={currentTab === `deposits` ? `current-tab` : ``}
            onClick={handleTabClick}
          >
            Deposits
          </li>
          <li
            className={currentTab === `withdrawals` ? `current-tab` : ``}
            onClick={handleTabClick}
          >
            Withdrawals
          </li>
          <li
            className={currentTab === `loans` ? `current-tab` : ``}
            onClick={handleTabClick}
          >
            Loans
          </li>
          <li
            className={currentTab === `investments` ? `current-tab` : ``}
            onClick={handleTabClick}
          >
            Investments
          </li>
        </ul>
      </div>

      <div className='card-container'>
        {currentTab === `deposits` ? (
          <Deposits />
        ) : currentTab === `withdrawals` ? (
          <Withdrawals />
        ) : currentTab === `loans` ? (
          <Loans />
        ) : currentTab === `investments` ? (
          <Investments />
        ) : (
          ``
        )}
      </div>
    </main>
  );
};

const Deposits = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState([]);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const fetchDeposits = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/deposit/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setDeposits(data.allDeposits);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeposits();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className='card-loading'>
        <img src={spinner} alt='' />
      </div>
    );
  }

  return (
    <div className='transaction-card deposits'>
      <div className='title'>
        <h3>Deposits</h3>
      </div>
      <>
        {deposits.length < 1 ? (
          <div className='no-transaction'>
            <h3>No Deposit Transaction Yet</h3>
          </div>
        ) : (
          <div className='table'>
            <ul className='headers'>
              <li>Type</li>
              <li>Amount</li>
              <li>Status</li>
              <li>Date</li>
              <li>Reference</li>
            </ul>
            {deposits.map((transaction) => {
              const { _id, status, date, reference, amount, via } = transaction;

              return (
                <div className='transaction' key={_id}>
                  <div className='type item'>
                    <img src={creditTransaction} alt='credit transaction' />
                    <p className='via'>{via}</p>
                  </div>
                  <div className='amount item'>
                    <p>${amount ? amount.toLocaleString(`en-US`) : 0.0}</p>
                  </div>
                  <div className='status item'>
                    <p className={`status ${status}`}>
                      {status === `pending` ? `Processing` : status}
                    </p>
                  </div>
                  <div className='date item'>
                    <p>{date}</p>
                  </div>
                  <div className='reference item'>
                    <p>{reference}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    </div>
  );
};

const Withdrawals = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [withdrawals, setWithdrawals] = useState([]);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const fetchWithdrawals = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/withdrawal/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setWithdrawals(data.allWithdrawals);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWithdrawals();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className='card-loading'>
        <img src={spinner} alt='' />
      </div>
    );
  }

  return (
    <div className='transaction-card deposits'>
      <div className='title'>
        <h3>Withdrawals</h3>
      </div>

      {withdrawals.length < 1 ? (
        <div className='no-transaction'>
          <h3>No Withdrawal Transaction Yet</h3>
        </div>
      ) : (
        <div className='table'>
          <ul className='headers'>
            <li>Type</li>
            <li>Amount</li>
            <li>Status</li>
            <li>Date</li>
            <li>Reference</li>
          </ul>
          {withdrawals.map((transaction) => {
            const { _id, status, date, reference, amount, via } = transaction;

            return (
              <div className='transaction' key={_id}>
                <div className='type item'>
                  <img src={debitTransaction} alt='debit transaction' />
                  <p className='via'>{via} withdrawal</p>
                </div>
                <div className='amount item'>
                  <p>${amount ? amount.toLocaleString(`en-US`) : 0.0}</p>
                </div>
                <div className='status item'>
                  <p className={`status ${status}`}>
                    {status === `pending` ? `Processing` : status}
                  </p>
                </div>
                <div className='date item'>
                  <p>{date}</p>
                </div>
                <div className='reference item'>
                  <p>{reference}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Loans = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [loans, setLoans] = useState([]);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const fetchLoans = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/loan/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setLoans(data.allLoans);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoans();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className='card-loading'>
        <img src={spinner} alt='' />
      </div>
    );
  }

  return (
    <div className='transaction-card loans'>
      <div className='title'>
        <h3>Loans</h3>
      </div>
      <>
        {loans.length < 1 ? (
          <div className='no-transaction'>
            <h3>No Loan transaction yet</h3>
          </div>
        ) : (
          <div className='table'>
            <ul className='headers'>
              <li>Loan Type</li>
              <li>Amount</li>
              <li>Duration</li>
              <li>Reference</li>
              <li>Date</li>
              <li>Status</li>
            </ul>
            {loans.map((loan) => {
              const {
                _id,
                status,
                date,
                reference,
                loanAmount,
                loanDuration,
                loanType,
              } = loan;

              return (
                <div className='transaction' key={_id}>
                  <div className='type item'>
                    <img src={creditTransaction} alt='credit transaction' />
                    <p className='via'>{loanType}</p>
                  </div>
                  <div className='amount item'>
                    <p>
                      ${loanAmount ? loanAmount.toLocaleString(`en-US`) : 0.0}
                    </p>
                  </div>
                  <div className='duration item'>
                    <p>{loanDuration}</p>
                  </div>
                  <div className='reference item'>
                    <p>{reference}</p>
                  </div>
                  <div className='date item'>
                    <p>{date}</p>
                  </div>
                  <div className='status item'>
                    <p className={`status ${status}`}>
                      {status === `pending` ? `Processing` : status}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </>
    </div>
  );
};

const Investments = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const fetchInvestments = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/invest/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setInvestments(data.allInvestments);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestments();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className='card-loading'>
        <img src={spinner} alt='' />
      </div>
    );
  }

  return (
    <div className='transaction-card deposits'>
      <div className='title'>
        <h3>Investments</h3>
      </div>
      {investments.length < 1 ? (
        <div className='no-transaction'>
          <h3>No Investment transaction yet</h3>
        </div>
      ) : (
        <div className='table'>
          <ul className='headers'>
            <li>Loan Type</li>
            <li>Amount</li>
            <li>Duration</li>
            <li>Reference</li>
            <li>Date</li>
            <li>Status</li>
          </ul>
          {investments.map((investment) => {
            const {
              _id,
              status,
              date,
              reference,
              loanAmount,
              loanDuration,
              loanType,
            } = investment;

            return (
              <div className='transaction' key={_id}>
                <div className='type item'>
                  <img src={creditTransaction} alt='credit transaction' />
                  <p className='via'>{loanType}</p>
                </div>
                <div className='amount item'>
                  <p>
                    ${loanAmount ? loanAmount.toLocaleString(`en-US`) : 0.0}
                  </p>
                </div>
                <div className='duration item'>
                  <p>{loanDuration}</p>
                </div>
                <div className='reference item'>
                  <p>{reference}</p>
                </div>
                <div className='date item'>
                  <p>{date}</p>
                </div>
                <div className='status item'>
                  <p className={`status ${status}`}>
                    {status === `pending` ? `Processing` : status}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
