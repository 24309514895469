import { useEffect } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import {
  AboutUs,
  CheckEmail,
  ContactUs,
  EmailVerified,
  FAQs,
  ForgotPassword,
  Home,
  LoansPage,
  Login,
  PrivacyPolicies,
  Reigster,
  ResetPassword,
  SavingsInvestments,
  Security,
  TermsConditions,
  VerifyEmail,
} from './pages/regular';

import {
  AccountSettings,
  BankDeposit,
  BankWithdrawal,
  Cards,
  CheckLoanEligibility,
  CreditCards,
  CryptoDeposit,
  CryptoWithdrawal,
  DebitCards,
  Deposit,
  Loans,
  Notifications,
  Overview,
  PaypalDeposit,
  PaypalWithdrawal,
  RepayLoan,
  RequestLoan,
  Settings,
  SkrillWithdrawal,
  TransactionHistory,
  Withdraw,
} from './pages/dashboard';

import PrivateRoutes from './pages/dashboard/PrivateRoutes';
import { useGlobalContext } from './context/context';
import { Slide, ToastContainer } from 'react-toastify';

function App() {
  const { pathname } = useLocation();
  const { showDashboardNavbar, showRegularNavbar, wakeServer } =
    useGlobalContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (showDashboardNavbar || showRegularNavbar) {
      document.body.style.overflow = `hidden`;
    } else {
      document.body.style.overflow = `auto`;
    }
  }, [showDashboardNavbar, showRegularNavbar]);

  useEffect(() => {
    wakeServer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        {/* REGULAR ROUTES */}
        <Route path='/register' element={<Reigster />} />
        <Route path='/login' element={<Login />} />
        <Route path='/email-verification' element={<VerifyEmail />} />
        <Route path='/email-verified' element={<EmailVerified />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/check-email' element={<CheckEmail />} />
        <Route path='/update-password' element={<ResetPassword />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/loans' element={<LoansPage />} />
        <Route path='/savings-investments' element={<SavingsInvestments />} />
        <Route path='/security' element={<Security />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/terms-&-conditions' element={<TermsConditions />} />
        <Route path='/privacy-policies' element={<PrivacyPolicies />} />
        <Route path='/frequently-asked-questions' element={<FAQs />} />
        <Route path='/' element={<Home />} />

        {/* DASHBOARD ROUTES */}
        <Route element={<PrivateRoutes />}>
          <Route path='/dashboard/overview' element={<Overview />} />
          <Route path='/dashboard/notifications' element={<Notifications />} />
          <Route
            path='/dashboard/transaction-history'
            element={<TransactionHistory />}
          />
          <Route path='/dashboard/account' element={<AccountSettings />} />
          <Route path='/dashboard/settings' element={<Settings />} />
          <Route path='/dashboard/deposit' element={<Deposit />} />
          <Route path='/dashboard/deposit/crypto' element={<CryptoDeposit />} />
          <Route
            path='/dashboard/deposit/bank-deposit'
            element={<BankDeposit />}
          />
          <Route
            path='/dashboard/deposit/paypal-deposit'
            element={<PaypalDeposit />}
          />
          <Route path='/dashboard/loans' element={<Loans />} />
          <Route
            path='/dashboard/loans/request-loan'
            element={<RequestLoan />}
          />
          <Route
            path='/dashboard/loans/check-eligibility'
            element={<CheckLoanEligibility />}
          />
          <Route path='/dashboard/loans/repay-loan' element={<RepayLoan />} />
          <Route path='/dashboard/withdraw' element={<Withdraw />} />
          <Route
            path='/dashboard/withdraw/crypto-withdrawal'
            element={<CryptoWithdrawal />}
          />
          <Route
            path='/dashboard/withdraw/bank-transfer'
            element={<BankWithdrawal />}
          />
          <Route
            path='/dashboard/withdraw/paypal-withdrawal'
            element={<PaypalWithdrawal />}
          />
          <Route
            path='/dashboard/withdraw/skrill-withdrawal'
            element={<SkrillWithdrawal />}
          />
          <Route path='/dashboard/cards' element={<Cards />} />
          <Route
            path='/dashboard/cards/credit-cards'
            element={<CreditCards />}
          />
          <Route path='/dashboard/cards/debit-cards' element={<DebitCards />} />
        </Route>
      </Routes>
      <ToastContainer transition={Slide} />
    </>
  );
}

export default App;
