import loginImg from '../../../assets/auth-img.png';
import registerImg from '../../../assets/reg-auth-img.png';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import spinner from '../../../assets/rolling-spinner-white.svg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import companyLogo from '../../../assets/company-logo.svg';

export const Reigster = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { baseURL, revealPassword } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');

  const formik = useFormik({
    initialValues: {
      name: ``,
      email: ``,
      accountType: ``,
      password: ``,
      confirmPassword: ``,
      address: ``,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Full name is required')
        .test(
          'at-least-two-names',
          'Full name must have at least two names',
          (value) => {
            const names = value ? value.trim().split(' ') : [];
            return names.length >= 2;
          }
        ),
      email: Yup.string()
        .email(`Enter a valid email address`)
        .required(`Email is required`),
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Input password again'),
      accountType: Yup.string().required('Account type is required'),
      address: Yup.string().required('Address is required'),
    }),
    onSubmit() {
      registerUser();
    },
  });

  const registerUser = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/register`, {
        ...formik.values,
        phoneNumber: phone,
        country: country,
        region: region,
      });
      sessionStorage.setItem(`userInfo`, JSON.stringify(data));
      setLoading(false);
      setErrorMsg(``);
      navigate(`/email-verification`);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <main className='auth-page register-page'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
        <div className='desktop-alternative'>
          <p>Already have an account?</p>
          <button
            className='transparent'
            type='button'
            onClick={() => navigate(`/login`)}
          >
            Login Here
          </button>
        </div>
      </div>

      <div className='form-container'>
        <form action='' onSubmit={formik.handleSubmit}>
          <div className='mobile-alternative'>
            <p>Already have an account?</p>
            <button
              className='transparent'
              type='button'
              onClick={() => navigate(`/login`)}
            >
              Login Here
            </button>
          </div>
          <div className='header'>
            <h4>Financial freedom at your fingertips</h4>
            <h3>Sign up your account</h3>
          </div>

          {/* FULL NAME */}
          <div className='form-control'>
            <label
              htmlFor='name'
              className={
                formik.touched.name && formik.errors.name ? `error` : ``
              }
            >
              {formik.touched.name && formik.errors.name
                ? formik.errors.name
                : `Full Name`}
            </label>
            <input
              name='name'
              type='text'
              id='name'
              placeholder='e.g John Doe'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* EMAIL */}
          <div className='form-control'>
            <label
              htmlFor='email'
              className={
                formik.touched.email && formik.errors.email ? `error` : ``
              }
            >
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : `Email`}
            </label>
            <input
              name='email'
              type='email'
              id='email'
              placeholder='e.g johndoe@email.com'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* COUNTRY */}
          <div className='form-control'>
            <label htmlFor='country'>Country</label>
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
            />
          </div>

          {/* REGION */}
          <div className='form-control'>
            <label htmlFor='region'>Region</label>
            <RegionDropdown
              country={country}
              value={region}
              onChange={(val) => setRegion(val)}
              required
            />
          </div>

          {/* RESIDENTIAL ADDRESS */}
          <div className='form-control'>
            <label
              htmlFor='address'
              className={
                formik.touched.address && formik.errors.address ? `error` : ``
              }
            >
              {formik.touched.address && formik.errors.address
                ? formik.errors.address
                : `Residential Address`}
            </label>
            <input
              name='address'
              type='text'
              id='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* PHONE NUMBER */}
          <div className='form-control'>
            <label
              htmlFor='phone'
              className={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? `error`
                  : ``
              }
            >
              {formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : `Phone Number`}
            </label>
            <PhoneInput
              inputProps={{
                name: 'phone',
                required: true,
              }}
              country={'us'}
              value={phone}
              onChange={(newPhone) => setPhone(newPhone)}
              containerClass='phone-input'
              inputClass='input'
              buttonClass='btn'
            />
          </div>

          {/* ACCOUNT TYPE */}
          <div className='form-control'>
            <label
              htmlFor='phone'
              className={
                formik.touched.accountType && formik.errors.accountType
                  ? `error`
                  : ``
              }
            >
              {formik.touched.accountType && formik.errors.accountType
                ? formik.errors.accountType
                : `Account Type`}
            </label>
            <select
              name='accountType'
              id='account'
              value={formik.values.accountType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden>Select</option>
              <option value='Savings Account'>Savings Account</option>
              <option value='Current Account'>Current Account</option>
              <option value='Fixed Deposit'>Fixed Deposit</option>
            </select>
          </div>

          {/* PASSWORD */}
          <div className='form-control'>
            <label
              htmlFor='password'
              className={
                formik.touched.password && formik.errors.password ? `error` : ``
              }
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : `Password`}
            </label>
            <div className='password-container'>
              <input
                name='password'
                type='password'
                id='password'
                placeholder='Enter your password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {showPassword ? (
                <FaEyeSlash
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FaEye
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
          </div>

          {/* CONFIRM PASSWORD */}
          <div className='form-control'>
            <label
              htmlFor='confirm-password'
              className={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? `error`
                  : ``
              }
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : `Confirm Password`}
            </label>
            <input
              name='confirmPassword'
              type='password'
              id='confirm-password'
              placeholder='Confirm your password'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* AUTH ERROR MESSAGE */}
          {errorMsg && (
            <div className='auth-error'>
              <p>{errorMsg}</p>
            </div>
          )}

          {/* TERMS AND CONDITIONS */}
          <p className='terms-and-conditions'>
            By clicking sign up, you agree to our{' '}
            <Link to='/terms-&-conditions'>Terms of use</Link> &{' '}
            <Link to='/privacy-policies'>privacy policies</Link>
          </p>

          {/* SUBMIT BUTTON */}
          <div className='submit-btn'>
            <button className='blue long' type='submit'>
              Submit {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
        <div className='img'>
          <img src={registerImg} alt='register with us' />
        </div>
      </div>
    </main>
  );
};

export const Login = () => {
  const navigate = useNavigate();
  const { baseURL, revealPassword } = useGlobalContext();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);

  const formik = useFormik({
    initialValues: {
      email: ``,
      password: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Enter a valid email address`)
        .required(`Email is required`),
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('Password is required'),
    }),
    onSubmit() {
      loginUser();
    },
  });

  const loginUser = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/login`, formik.values);
      sessionStorage.setItem(`userInfo`, JSON.stringify(data));
      sessionStorage.setItem(`userToken`, JSON.stringify(data.token));
      setLoading(false);
      setErrorMsg(``);
      navigate(`/dashboard/overview`);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <main className='auth-page login-page'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
        <div className='desktop-alternative'>
          <p>Don't have an account?</p>
          <button className='transparent' onClick={() => navigate(`/register`)}>
            Register Here
          </button>
        </div>
      </div>
      <div className='form-container'>
        <form action='' onSubmit={formik.handleSubmit}>
          <div className='mobile-alternative'>
            <p>Don't have an account?</p>
            <button
              className='transparent'
              onClick={() => navigate(`/register`)}
              type='button'
            >
              Register Here
            </button>
          </div>
          <div className='header'>
            <h4>Financial freedom at your fingertips</h4>
            <h3>Sign in to your account</h3>
          </div>

          {/* EMAIL */}
          <div className='form-control'>
            <label
              htmlFor='email'
              className={
                formik.touched.email && formik.errors.email ? `error` : ``
              }
            >
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : `Email`}
            </label>
            <input
              type='email'
              id='email'
              placeholder='e.g johndoe@email.com'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* PASSWORD */}
          <div className='form-control'>
            <label
              htmlFor='password'
              className={
                formik.touched.password && formik.errors.password ? `error` : ``
              }
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : `Password`}
            </label>
            <div className='password-container'>
              <input
                type='password'
                id='password'
                placeholder='Enter your password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {showPassword ? (
                <FaEyeSlash
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FaEye
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
          </div>

          {errorMsg && (
            <div className='auth-error'>
              <p>{errorMsg}</p>
            </div>
          )}

          <div className='forgot-password'>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </div>

          <div className='submit-btn'>
            <button className='blue long' type='submit'>
              Login {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
        <div className='img'>
          <img src={loginImg} alt='register with us' />
        </div>
      </div>
    </main>
  );
};
