import React, { useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { StatusModal } from '../../../components/dashboard/modals/Modals';
import { useGlobalContext } from '../../../context/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import spinner from '../../../assets/rolling-spinner-white.svg';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import { FaChevronLeft } from 'react-icons/fa';
import supportImg from '../../../assets/support.jpg';

export const Loans = () => {
  return (
    <main className='loans-page'>
      <Navbar moblieNavText={`Loans`} currentPage={`loans`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Loans</h3>
      </div>
      <div className='options'>
        <Link to='/dashboard/loans/request-loan' className='card'>
          <h3>Request loan</h3>
        </Link>
        <Link to='/dashboard/loans/repay-loan' className='card'>
          <h3>Repay loan</h3>
        </Link>
        <Link to='/dashboard/loans/check-eligibility' className='card'>
          <h3>Check eligibility</h3>
        </Link>
        {/* <Link to='/dashboard/deposit/paypal' className='card'>
          <h3>Loan history</h3>
        </Link> */}
      </div>
    </main>
  );
};

export const RequestLoan = () => {
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const formik = useFormik({
    initialValues: {
      loanType: ``,
      loanAmount: ``,
      loanDuration: ``,
    },
    validationSchema: Yup.object({
      loanType: Yup.string().required(`Loan Type is required`),
      loanAmount: Yup.number().required(`Loan Amount is required`),
      loanDuration: Yup.string().required(`Loan Duration is required`),
    }),
    onSubmit() {
      sendLoanRequest();
    },
  });

  const sendLoanRequest = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/loan/add`,
        {
          loanType: formik.values.loanType,
          loanAmount: Number(formik.values.loanAmount),
          loanDuration: formik.values.loanDuration,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <main className='loans-page request-loan'>
      <Navbar moblieNavText={`Request Loan`} currentPage={`loans`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/loans`)} />
        <h3>Request Loan</h3>
      </div>

      <div className='loan-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          {/* LOAN TYPE */}
          <div className='form-control'>
            <label
              htmlFor='loanType'
              className={
                formik.touched.loanType && formik.errors.loanType ? `error` : ``
              }
            >
              {formik.touched.loanType && formik.errors.loanType
                ? formik.errors.loanType
                : `Loan Type`}
            </label>
            <select
              name='loanType'
              id='loanType'
              value={formik.values.loanType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select loan type
              </option>
              <option value='Business loan'>Business loan</option>
              <option value='Car finance'>Car finance</option>
              <option value='Fixed rate loan'>Fixed rate loan</option>
              <option value='Installment loan'>Installment loan</option>
              <option value='Term loan'>Term loan </option>
              <option value='SME loan'>SME loan</option>
            </select>
          </div>

          {/* LOAN AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='loanType'
              className={
                formik.touched.loanAmount && formik.errors.loanAmount
                  ? `error`
                  : ``
              }
            >
              {formik.touched.loanAmount && formik.errors.loanAmount
                ? formik.errors.loanAmount
                : `Loan Amount`}
            </label>
            <select
              name='loanAmount'
              id='loanAmount'
              value={formik.values.loanAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select loan Amount
              </option>
              <option value='10000'>&#36;10,000</option>
              <option value='30000'>&#36;30,000</option>
              <option value='50000'>&#36;50,000</option>
              <option value='100000'>&#36;100,000</option>
            </select>
          </div>

          {/* LOAN DURATION */}
          <div className='form-control'>
            <label
              htmlFor='loanDuration'
              className={
                formik.touched.loanDuration && formik.errors.loanDuration
                  ? `error`
                  : ``
              }
            >
              {formik.touched.loanDuration && formik.errors.loanDuration
                ? formik.errors.loanDuration
                : `Loan Duration`}
            </label>
            <select
              name='loanDuration'
              id='loanDuration'
              value={formik.values.loanDuration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select loan duration
              </option>
              <option value='1 Month'>1 Month</option>
              <option value='3 Month'>3 Month</option>
              <option value='5 Month'>5 Month</option>
              <option value='1 Year'>1 Year</option>
            </select>
          </div>

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button className='blue' type='submit'>
              Request Loan {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showStatusModal ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={`Loan request sent successfully`}
          navigationUrl={`/dashboard/transaction-history`}
          status={`success`}
        />
      ) : null}
    </main>
  );
};

export const CheckLoanEligibility = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      loanReason: ``,
      loanAmount: ``,
      employmentStatus: ``,
      monthlyIncome: ``,
    },
    validationSchema: Yup.object({
      loanReason: Yup.string().required(`Loan Reason is required`),
      loanAmount: Yup.number().required(`Loan Amount is required`),
      employmentStatus: Yup.string().required(`Employment Status is required`),
      monthlyIncome: Yup.string().required(`Monthly Income is required`),
    }),
    onSubmit() {
      sendLoanRequest();
    },
  });

  const sendLoanRequest = async () => {
    try {
    } catch (error) {}
  };

  return (
    <main className='loans-page check-eligibility'>
      <Navbar moblieNavText={`Check Eligibility`} currentPage={`loans`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/loans`)} />

        <h3>Check Eligibility</h3>
      </div>

      <div className='loan-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          {/* LOAN REASON */}
          <div className='form-control'>
            <label
              htmlFor='loanReason'
              className={
                formik.touched.loanReason && formik.errors.loanReason
                  ? `error`
                  : ``
              }
            >
              {formik.touched.loanReason && formik.errors.loanReason
                ? formik.errors.loanReason
                : `What do you need the loan for?`}
            </label>
            <select
              name='loanReason'
              id='loanReason'
              value={formik.values.loanReason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select loan reason
              </option>
              <option value='Personal'>Personal</option>
              <option value='Business'>Business</option>
              <option value='Purchase'>Purchase</option>
              <option value='Health'>Health</option>
            </select>
          </div>

          {/* LOAN AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='loanAmount'
              className={
                formik.touched.loanAmount && formik.errors.loanAmount
                  ? `error`
                  : ``
              }
            >
              {formik.touched.loanAmount && formik.errors.loanAmount
                ? formik.errors.loanAmount
                : `How much do you need?`}
            </label>
            <select
              name='loanAmount'
              id='loanAmount'
              value={formik.values.loanAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select loan Amount
              </option>
              <option value='10000'>&#36;10,000</option>
              <option value='30000'>&#36;30,000</option>
              <option value='50000'>&#36;50,000</option>
              <option value='100000'>&#36;100,000</option>
            </select>
          </div>

          {/* EMPLOYMENT STATUS */}
          <div className='form-control'>
            <label
              htmlFor='employmentStatus'
              className={
                formik.touched.employmentStatus &&
                formik.errors.employmentStatus
                  ? `error`
                  : ``
              }
            >
              {formik.touched.employmentStatus && formik.errors.employmentStatus
                ? formik.errors.employmentStatus
                : `Employment Status`}
            </label>
            <select
              name='employmentStatus'
              id='employmentStatus'
              value={formik.values.employmentStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select employment status
              </option>
              <option value='Employed'>Employed</option>
              <option value='Unemployed'>Unemployed</option>
            </select>
          </div>

          {/* MONTHLY INCOME */}
          <div className='form-control'>
            <label
              htmlFor='monthlyIncome'
              className={
                formik.touched.monthlyIncome && formik.errors.monthlyIncome
                  ? `error`
                  : ``
              }
            >
              {formik.touched.monthlyIncome && formik.errors.monthlyIncome
                ? formik.errors.monthlyIncome
                : `Monthly income`}
            </label>
            <select
              name='monthlyIncome'
              id='monthlyIncome'
              value={formik.values.monthlyIncome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select monthly income
              </option>
              <option value='0-1000'>&#36;0 - &#36;1,000</option>
              <option value='1000-3000'>&#36;1,000 - &#36;3,000</option>
              <option value='3000-5000'>&#36;3,000 - &#36;5,000</option>
              <option value='5000-10000'>&#36;5,000 - &#36;10,000</option>
            </select>
          </div>

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button
              className='blue'
              type='submit'
              onClick={() => {
                const {
                  employmentStatus,
                  loanAmount,
                  loanReason,
                  monthlyIncome,
                } = formik.values;
                if (
                  employmentStatus &&
                  loanAmount &&
                  loanReason &&
                  monthlyIncome
                ) {
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                    setShowStatusModal(true);
                  }, 3000);
                }
              }}
            >
              Check Eligibility {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showStatusModal ? (
        <StatusModal
          dismissFunction={setShowStatusModal}
          message={`Your eligibility is being processed, you will receive a notification shortly confirming your eligibility status.`}
          navigationUrl={`/dashboard/overview`}
          status={`success`}
        />
      ) : null}
    </main>
  );
};

export const RepayLoan = () => {
  const navigate = useNavigate();

  return (
    <main className='deposit-page crypto'>
      <Navbar moblieNavText={`Repay Loan`} currentPage={`loans`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/loans`)} />
        <h3>Repay Loan</h3>
      </div>
      <ContactSupport />
    </main>
  );
};

const ContactSupport = () => {
  return (
    <section className='contact-support'>
      <div className='img'>
        <img src={supportImg} alt='' />
      </div>
      <h1>Please contact support to continue with this transaction</h1>
    </section>
  );
};
