import React, { useEffect, useState } from 'react';
import settingsIcon from '../../../assets/settings-icon.svg';
import notificationsIcon from '../../../assets/notification-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import companyLogo from '../../../assets/company-logo-alt.svg';
import {
  FaFunnelDollar,
  FaPiggyBank,
  FaPowerOff,
  FaUser,
  FaUserAlt,
} from 'react-icons/fa';
import {
  BsGrid1X2Fill,
  BsFillGearFill,
  BsFillCreditCard2BackFill,
  BsArrowLeftRight,
} from 'react-icons/bs';
import hamburger from '../../../assets/hamburger.svg';
import { useGlobalContext } from '../../../context/context';
import axios from 'axios';

const Navbar = ({ currentPage }) => {
  const { showDashboardNavbar, setShowDashboardNavbar, baseURL } =
    useGlobalContext();
  const [notificaions, setNotifications] = useState(``);

  const { owner, accountNumber } = JSON.parse(
    sessionStorage.getItem(`userInfo`)
  )
    ? JSON.parse(sessionStorage.getItem(`userInfo`))
    : ``;
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const navigate = useNavigate();

  const handleLinkClick = () => {
    setShowDashboardNavbar(false);
  };

  // get notifications
  const getNotifications = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/notification/all
`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotifications(
        data.allNotifications.filter((notif) => !notif.read).length
      );
    } catch (error) {}
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, []);

  return (
    <nav className='dashboard-navbar'>
      {/* MOBILE NAVBAR */}
      <div className='mobile'>
        <div className='logo'>
          <Link to='/dashboard/overview'>
            <img src={companyLogo} alt='company logo' />
          </Link>
        </div>
        <div className='hamburger-notification'>
          <Link to='/dashboard/notifications' className='notif'>
            <img src={notificationsIcon} alt='notifications button' />
            {notificaions > 0 && <p>{notificaions}</p>}
          </Link>
          <div className='ham' onClick={() => setShowDashboardNavbar(true)}>
            <img src={hamburger} alt='open menu button' />
          </div>
        </div>
        <div className={`mob-nav ${showDashboardNavbar ? `show` : ``}`}>
          <div
            className='void'
            onClick={() => setShowDashboardNavbar(false)}
          ></div>
          <div className='items-container'>
            <div className='name-account-profile'>
              <div className='profile' onClick={handleLinkClick}>
                <Link to='/dashboard/account'>
                  <FaUser />
                </Link>
              </div>
              <div className='info'>
                <h3>{owner}</h3>
                <p>{accountNumber}</p>
              </div>
            </div>

            <ul className='list'>
              <li
                className={currentPage === `overview` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <BsGrid1X2Fill /> <Link to='/dashboard/overview'>Overview</Link>
              </li>
              <li
                className={currentPage === `transactions` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <BsArrowLeftRight />
                <Link to='/dashboard/transaction-history'>Transactions</Link>
              </li>
              <li
                className={currentPage === `cards` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <BsFillCreditCard2BackFill />
                <Link to='/dashboard/cards'>Cards</Link>
              </li>
              <li
                className={currentPage === `loans` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <FaFunnelDollar /> <Link to='/dashboard/loans'>Loans</Link>
              </li>
              <li
                className={currentPage === `investments` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <FaPiggyBank />
                <Link to='/dashboard/overview'>Investments</Link>
              </li>
              <li
                className={
                  currentPage === `account settings` ? `active-page` : ``
                }
                onClick={handleLinkClick}
              >
                <FaUserAlt />{' '}
                <Link to='/dashboard/account'>Account Settings</Link>
              </li>
              <li
                className={currentPage === `settings` ? `active-page` : ``}
                onClick={handleLinkClick}
              >
                <BsFillGearFill />{' '}
                <Link to='/dashboard/settings'>Settings</Link>
              </li>
            </ul>

            <div
              className='sign-out'
              onClick={() => {
                navigate(`/`);
                sessionStorage.removeItem(`userInfo`);
                handleLinkClick();
              }}
            >
              <FaPowerOff /> <p>Sign out</p>
            </div>
          </div>
        </div>
      </div>

      {/* DESKTOP NAVBAR */}
      <div className='desktop'>
        <div className='top'>
          <div className='logo'>
            <Link to='/dashboard/overview'>
              <img src={companyLogo} alt='company logo' />
            </Link>
          </div>
          <div className='profile-notif-settings'>
            <Link to='/dashboard/account' className='item profile'>
              <FaUser />
            </Link>
            <Link to='/dashboard/notifications' className='item notif'>
              <img src={notificationsIcon} alt='notifications' />
              {notificaions > 0 && <p>{notificaions}</p>}
            </Link>
            <Link to='/dashboard/settings' className='item'>
              <img src={settingsIcon} alt='settings' />
            </Link>
            <Link className='item logout'>
              <FaPowerOff
                onClick={() => {
                  navigate(`/`);
                  sessionStorage.removeItem(`userInfo`);
                  handleLinkClick();
                }}
              />
            </Link>
          </div>
        </div>
        <div className='name'>
          <h2>Welcome {owner}</h2>
        </div>
        <ul className='nav-pages'>
          <li className={currentPage === `overview` ? `active` : ``}>
            <Link to='/dashboard/overview'>Overview</Link>
          </li>
          <li className={currentPage === `transactions` ? `active` : ``}>
            <Link to='/dashboard/transaction-history'>Transactions</Link>
          </li>
          <li className={currentPage === `cards` ? `active` : ``}>
            <Link to='/dashboard/cards'>Cards</Link>
          </li>
          <li className={currentPage === `loans` ? `active` : ``}>
            <Link to='/dashboard/loans'>Loans</Link>
          </li>
          <li className={currentPage === `investments` ? `active` : ``}>
            <Link to='/dashboard/overview'>Investments</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
