import { Link, useNavigate } from 'react-router-dom';
import email from '../../../assets/email.png';
import confetti from '../../../assets/confetti.png';
import companyLogo from '../../../assets/company-logo.svg';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  return (
    <main className='email-verification verify-email'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
        <div className='desktop-alternative'>
          <p>Don't have an account?</p>
          <button className='transparent' onClick={() => navigate(`/register`)}>
            Register Here
          </button>
        </div>
      </div>
      <div className='mobile-alternative'>
        <p>Don't have an account?</p>
        <button
          className='transparent'
          onClick={() => navigate(`/register`)}
          type='button'
        >
          Register Here
        </button>
      </div>

      {/* CHECK EMAIL */}
      <div className='check-email'>
        <div className='img'>
          <img src={email} alt='email' />
        </div>
        <div className='text'>
          <h4>Confirm your mail</h4>
          <p>
            We have sent a verification email to you. Please check your email
            for the verification message and complete your registration.
          </p>
        </div>
      </div>
    </main>
  );
};

export const EmailVerified = () => {
  const navigate = useNavigate();
  return (
    <main className='email-verification verify-email'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
        <div className='desktop-alternative'>
          <p>Don't have an account?</p>
          <button className='transparent' onClick={() => navigate(`/register`)}>
            Register Here
          </button>
        </div>
      </div>
      <div className='mobile-alternative'>
        <p>Don't have an account?</p>
        <button
          className='transparent'
          onClick={() => navigate(`/register`)}
          type='button'
        >
          Register Here
        </button>
      </div>

      {/* EMAIL VERIFIED */}
      <div className='email-verified'>
        <div className='img'>
          <img src={confetti} alt='confetti' />
        </div>
        <div className='text'>
          <h4>Congratulations!</h4>
          <p>
            Welcome to Elite Financial Bank. Sign in to your account and start
            enjoying all our wonderful services
          </p>
          <div className='btn'>
            <button className='blue long' onClick={() => navigate(`/login`)}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
