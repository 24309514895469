import React, { useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useFormik } from 'formik';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import spinner from '../../../assets/rolling-spinner-white.svg';
import PhoneInput from 'react-phone-input-2';

const AccountSettings = () => {
  const {
    email,
    owner,
    phoneNumber,
    accountType,
    accountNumber,
    country,
    region,
    tier,
  } = JSON.parse(sessionStorage.getItem(`userInfo`))
    ? JSON.parse(sessionStorage.getItem(`userInfo`))
    : ``;
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const [opened, { open, close }] = useDisclosure(false);
  const [newCountry, setNewCountry] = useState('');
  const [newRegion, setNewRegion] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState(``);
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const formatPhoneNumber = (phone) => {
    // Ensure that the phone number is in a valid format
    const cleaned = ('' + phone).replace(/\D/g, '');

    // Check if it's a valid phone number length
    const isValidLength = cleaned.length === 10 || cleaned.length === 11;

    if (!isValidLength) {
      return phone;
    }

    // Format the phone number based on its length
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`;
    } else {
      return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)}) ${cleaned.slice(
        4,
        7
      )}-${cleaned.slice(7)}`;
    }
  };

  const formik = useFormik({
    initialValues: {
      address: ``,
    },
    onSubmit() {
      updateUser();
    },
  });

  const updateUser = async () => {
    // const { address } = formik.values;

    const formData = new FormData();
    if (phone) {
      formData.append(`phoneNumber`, phone);
    }
    if (image) {
      formData.append(`image`, image);
    }

    try {
      setLoading(true);
      await axios.put(`${baseURL}/auth/edit-user`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <main className='account-settings-page'>
      <Navbar moblieNavText={`Account Settings`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Account Settings</h3>
      </div>

      {/* PERSONAL INFORMATION */}
      <div className='block'>
        <h4 className='block-title'>Personal Information</h4>
        <div className='card'>
          <div className='row'>
            <p>Name</p>
            <p className='name'>{owner}</p>
          </div>
          <div className='row'>
            <p>Email</p>
            <p>{email}</p>
          </div>
          <div className='row'>
            <p>Phone</p>
            <p>{formatPhoneNumber(phoneNumber)}</p>
          </div>
          <div className='row'>
            <p>Country</p>
            <p>{country}</p>
          </div>
          <div className='row'>
            <p>Region</p>
            <p>{region}</p>
          </div>
          <div className='btn'>
            <button className='blue' onClick={open}>
              Update Personal Details
            </button>
          </div>
        </div>
      </div>

      {/* ACCOUNT INFORMATION */}
      <div className='block'>
        <h4 className='block-title'>Account Information</h4>
        <div className='card'>
          <div className='row'>
            <p>Account type</p>
            <p className='account-type'>{accountType}</p>
          </div>
          <div className='row'>
            <p>Account number</p>
            <p>{accountNumber}</p>
          </div>
          <div className='row'>
            <p>Account status</p>
            <p>Active</p>
          </div>
          <div className='row'>
            <p>Tier</p>
            <p>{tier}</p>
          </div>
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        title='Update Personal Information'
        centered
        size={`lg`}
        className='update-info-modal'
      >
        <form action='' onSubmit={formik.handleSubmit}>
          <div className='form-row'>
            {/* PHONE NUMBER */}
            <div className='form-control'>
              <label
                htmlFor='phone'
                className={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? `error`
                    : ``
                }
              >
                {formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? formik.errors.phoneNumber
                  : `Phone Number`}
              </label>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                  required: true,
                }}
                country={'us'}
                value={phone}
                onChange={(newPhone) => setPhone(newPhone)}
                containerClass='phone-input'
                inputClass='input'
                buttonClass='btn'
              />
            </div>
            <div className='form-control'>
              <label htmlFor='country'>Country</label>
              <CountryDropdown
                value={newCountry}
                onChange={(val) => setNewCountry(val)}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-control'>
              <label htmlFor='email'>Region</label>
              <RegionDropdown
                country={newCountry}
                value={newRegion}
                onChange={(val) => setNewRegion(val)}
              />
            </div>
            <div className='form-control'>
              <label htmlFor='country'>Address</label>
              <input
                type='text'
                id='address'
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-control'>
              <label htmlFor='email'>Profile Picture</label>
              <input
                type='file'
                accept='image/png, image/gif, image/jpeg, image/webp'
                onChange={(e) => setImage(e.target.files)}
              />
            </div>
          </div>

          <div className='btn'>
            <button className='blue' type='submit'>
              Update Information
              {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </Modal>
    </main>
  );
};

export default AccountSettings;
