import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import companyLogo from '../../../assets/company-logo.svg';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import spinner from '../../../assets/rolling-spinner-white.svg';
import email from '../../../assets/email.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(``);
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const resetPassword = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/auth/forgot-password`, {
        email: email,
      });
      setLoading(false);
      navigate(`/check-email`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword();
  };

  return (
    <main className='forgot-password-page'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
      </div>
      <div className='form-container'>
        <form action='' onSubmit={handleSubmit}>
          <h2>Enter your email</h2>
          <p>
            Please enter your email address and we will send you an email with a
            link to reset your password.
          </p>
          <div className='form-control'>
            <input
              type='email'
              placeholder='Email address'
              value={email}
              id='email'
              name='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button className='blue'>
            Submit
            {loading && <img src={spinner} alt='' />}
          </button>
        </form>
        <p className='second-thoughts'>
          Don't want to reset your password anymore?
        </p>
        <button className='transparent' onClick={() => navigate(`/login`)}>
          Sign in here
        </button>
      </div>
    </main>
  );
};

export const CheckEmail = () => {
  return (
    <main className='forgot-password-page check-email'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
      </div>

      {/* CHECK EMAIL */}
      <div className='check-email'>
        <div className='img'>
          <img src={email} alt='email' />
        </div>
        <div className='text'>
          <h4>Check your email</h4>
          <p>We have sent you an email with a link to reset your password.</p>
        </div>
      </div>
    </main>
  );
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { baseURL, revealPassword } = useGlobalContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get(`email`);

  const formik = useFormik({
    initialValues: {
      password: ``,
      confirmPassword: ``,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'At least 1 special character and a number'
        )
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Input password again'),
    }),
    onSubmit() {
      resetPassword();
    },
  });

  const resetPassword = async () => {
    try {
      setLoading(true);
      await axios.put(`${baseURL}/auth/update-password`, {
        email: email,
        password: formik.values.password,
      });
      setLoading(false);
      toast.success(`Password reset successful`);
      navigate(`/login`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword();
  };

  return (
    <main className='forgot-password-page'>
      <div className='auth-navbar'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='company logo' />
        </Link>
      </div>
      <div className='form-container'>
        <form action='' onSubmit={handleSubmit}>
          <h2>Enter your new password</h2>
          <p>
            To make your password more secure, your password should contain at
            least one uppercase letter, one number and a special character.
          </p>

          {/* PASSWORD */}
          <div className='form-control'>
            <label
              htmlFor='password'
              className={
                formik.touched.password && formik.errors.password ? `error` : ``
              }
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : `New Password`}
            </label>
            <div className='password-container'>
              <input
                name='password'
                type='password'
                id='password'
                placeholder='Enter your password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {showPassword ? (
                <FaEyeSlash
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FaEye
                  className='icon'
                  onClick={() => {
                    revealPassword(`password`);
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
          </div>

          {/* CONFIRM PASSWORD */}
          <div className='form-control'>
            <label
              htmlFor='confirm-password'
              className={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? `error`
                  : ``
              }
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : `Confirm New Password`}
            </label>
            <input
              name='confirmPassword'
              type='password'
              id='confirm-password'
              placeholder='Confirm your password'
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <button className='blue'>
            Reset Password
            {loading && <img src={spinner} alt='' />}
          </button>
        </form>
      </div>
    </main>
  );
};
