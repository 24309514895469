import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import creditTransaction from '../../../assets/credit-transaction.svg';
import debitTransaction from '../../../assets/debit-transaction.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import mastercard from '../../../assets/mastercard.svg';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import spinner from '../../../assets/rolling-spinner-blue.svg';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';
import cardLogo from '../../../assets/card-logo.png';

const Overview = () => {
  const { owner } = JSON.parse(sessionStorage.getItem(`userInfo`))
    ? JSON.parse(sessionStorage.getItem(`userInfo`))
    : ``;
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const { fetchUpdateUserDetails } = useGlobalContext();

  useEffect(() => {
    fetchUpdateUserDetails(token);
    // eslint-disable-next-line
  }, []);

  return (
    <main className='overview-page'>
      <Navbar currentPage={`overview`} moblieNavText={`Welcome ${owner}`} />
      <BottomTab />

      <div className='customer-name'>
        <h3>Welcome {owner}</h3>
      </div>

      <div className='cards'>
        {/* MOBILE ARRANGEMENT */}
        <div className='mobile-arrangement'>
          <BalanceCard />
          <CryptosCard />
          <LoanInvestmentCard />
          <CardsCard />
          <TransactionHistoryCard />
        </div>

        {/* TABLET ARRANGEMENT */}
        <div className='tablet-arrangement'>
          <div className='row'>
            <BalanceCard />
            <CryptosCard />
          </div>
          <div className='row'>
            <LoanInvestmentCard />
            <TransactionHistoryCard />
          </div>
          <div className='row'>
            <CardsCard />
          </div>
        </div>

        {/* DESKTOP ARRANGEMENT */}
        <div className='desktop-arrangement'>
          <div className='row'>
            <BalanceCard />
            <LoanInvestmentCard />
          </div>
          <div className='row'>
            <TransactionHistoryCard />
            <div className='mini-row'>
              <CardsCard />
              <CryptosCard />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const BalanceCard = () => {
  const { accountNumber, totalBalance } = JSON.parse(
    sessionStorage.getItem(`userInfo`)
  )
    ? JSON.parse(sessionStorage.getItem(`userInfo`))
    : ``;

  return (
    <article className='balance-card card'>
      <div className='title-account-type'>
        <h4 className='title'>Total balance</h4>
        <h4 className='account-type'>
          Account Number <br />
          {accountNumber}
        </h4>
      </div>
      <h2 className='balance'>&#36;{totalBalance.toLocaleString(`en-US`)}</h2>
      <div className='btns'>
        <button className='white'>
          <Link to='/dashboard/deposit'>Deposit</Link>
        </button>
        <button className='white'>
          <Link to='/dashboard/withdraw'>Send Money</Link>
        </button>
      </div>
    </article>
  );
};

const CryptosCard = () => {
  return (
    <article className='cryptos-card card'>
      <div className='title'>
        <h4>Crypto wallet</h4>
      </div>
      <h2 className='balance'>${0}</h2>
      <div className='table'>
        <div className='btc item'>
          <h5>BTC</h5>
          <h2>&#36;0</h2>
        </div>
        <div className='eth item'>
          <h5>ETH</h5>
          <h2>&#36;0</h2>
        </div>
        <div className='usdt item'>
          <h5>USDT</h5>
          <h2>&#36;0</h2>
        </div>
        <div className='doge item'>
          <h5>DOGE</h5>
          <h2>&#36;0</h2>
        </div>
      </div>
    </article>
  );
};

const LoanInvestmentCard = () => {
  return (
    <article className='loan-investment-card card'>
      <div className='loan'>
        <div className='title'>
          <h4>Loan</h4>
        </div>
        <h2 className='balance'>&#36;{0}</h2>
        {/* <p className='brief'>Next repayment: 05/04/2023</p> */}
        <button className='transparent'>Repay Loan</button>
      </div>
      <div className='investment'>
        <div className='title'>
          <h4>Investment</h4>
        </div>
        <h2 className='balance'>&#36;{0}</h2>
        {/* <p className='brief'>34 days remaining</p> */}
        <button className='transparent'>View Investment</button>
      </div>
    </article>
  );
};

const TransactionHistoryCard = () => {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const { baseURL } = useGlobalContext();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRecentTransactions = async () => {
    try {
      const { data } = await axios.get(`${baseURL}/invest/withdrawal/sorted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTransactions(data.allTransactions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecentTransactions();
    // eslint-disable-next-line
  }, []);

  return (
    <article className='transaction-history-card card'>
      <div className='title'>
        <h4>Transaction history</h4>
      </div>
      <div className='sub-title'>
        <h5>Last five transactions</h5>
      </div>

      {loading ? (
        <div className='card-loader'>
          <img src={spinner} alt='' />
        </div>
      ) : !loading && transactions.length < 1 ? (
        <div className='no-transaction'>
          <h4>No Transaction Yet</h4>
        </div>
      ) : (
        <>
          <div className='past-transactions'>
            {transactions.slice(0, 4).map((transaction) => {
              const { _id, amount, type } = transaction;
              return (
                <div className='item' key={_id}>
                  <div className='type'>
                    {type === `deposit` ? (
                      <img src={creditTransaction} alt='' />
                    ) : (
                      <img src={debitTransaction} alt='' />
                    )}
                    <p>{type}</p>
                  </div>
                  <div className='amount'>
                    <p>
                      {type === `deposit` ? '+' : '-'} &#36;
                      {amount.toLocaleString(`en-US`)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='btn'>
            <button
              className='transparent long'
              onClick={() => {
                navigate(`/dashboard/transaction-history`);
              }}
            >
              View all transactions
            </button>
          </div>
        </>
      )}
    </article>
  );
};

const CardsCard = () => {
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const { baseURL } = useGlobalContext();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllCards = async () => {
    try {
      setLoading(false);
      const { data } = await axios.get(
        `${baseURL}/card/all
`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCards(data.allCards.filter((card) => card.status === `approved`));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCards();
    // eslint-disable-next-line
  }, []);

  return (
    <article className='cards-card card'>
      <div className='title'>
        <h4>Cards</h4>
      </div>
      <div className='container'>
        {loading ? (
          <div className='card-loader'>
            <img src={spinner} alt='' />
          </div>
        ) : !loading && cards.length < 1 ? (
          <div className='request-card'>
            <div className='credit-debit-card'>
              <div className='logo'>
                <img src={cardLogo} alt='elite financal bank' />
              </div>
              <div className='chip'></div>
              <div className='number'>
                <p>****</p>
                <p>****</p>
                <p>****</p>
                <p>****</p>
              </div>
              <div className='issuer-logo'>
                <img src={mastercard} alt='mastercard' />
              </div>
            </div>
            <Link to='/dashboard/cards'>Request Card +</Link>
          </div>
        ) : (
          <>
            <Link to='/dashboard/cards' className='request'>
              Request Card +
            </Link>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Pagination]}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1200: {
                  slidesPerView: 2,
                },
              }}
              className='mySwiper swiperContainer'
            >
              {cards.map((card, cardIndex) => {
                const { expiryDate, cardNumber, cvcCode } = card;
                const num = cardNumber.split(` `);
                return (
                  <SwiperSlide className='slide' key={cardIndex}>
                    <div className='credit-debit-card'>
                      <div className='logo'>
                        <img src={cardLogo} alt='elite financal bank' />
                      </div>
                      <div className='chip'></div>
                      <div className='number'>
                        {num.map((set, setIndex) => {
                          return <p key={setIndex}>{set}</p>;
                        })}
                      </div>
                      <div className='expiration-issuer'>
                        <p>{expiryDate}</p>
                        <p className='cvc'>{cvcCode}</p>
                        <img src={mastercard} alt='mastercard' />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </div>
    </article>
  );
};

export default Overview;
