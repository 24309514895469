import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import companyLogo from '../../../assets/company-logo-alt.svg';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='grid'>
        <div className='item logo-info'>
          <div className='logo'>
            <img src={companyLogo} alt='company logo' />
          </div>
          <p>
            At Elite Financial Bank, your financial security is our top
            priority. We are committed to providing you with a seamless and
            secure online banking experience.
          </p>
        </div>
        <div className='item list'>
          <h3>Company</h3>
          <ul>
            <li>
              <Link to='/about-us'>About us</Link>
            </li>
            <li>
              <Link to='/terms-&-conditions'>Terms & conditions</Link>
            </li>
            <li>
              <Link to='/privacy-policies'>Privacy policies</Link>
            </li>
          </ul>
        </div>
        <div className='item list'>
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to='/security'>Security</Link>
            </li>
          </ul>
        </div>
        <div className='item list'>
          <h3>Customer Support</h3>
          <ul>
            <li>
              <Link to='/contact-us'>Get help</Link>
            </li>
            <li>
              <Link to='/frequently-asked-questions'>FAQs</Link>
            </li>
            <li>
              <a href='mailto:support@elitefinancialhub.org'>Email us</a>
            </li>
          </ul>
        </div>
        <div className='item socials'>
          <h3>Follow us</h3>
          <ul>
            <li>
              <a href='/'>
                <FaFacebook />
              </a>
            </li>
            <li>
              <a href='/'>
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href='/'>
                <RiInstagramFill />
              </a>
            </li>
            <li>
              <a href='/'>
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright'>
        <p>
          Copyright © 2022 Elite Financial Bank. Licensed by the Central Bank of
          United States.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
