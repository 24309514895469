import { useEffect, useState } from 'react';
import { Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaTimes } from 'react-icons/fa';
import spinner from '../../../assets/rolling-spinner-white.svg';
import axios from 'axios';
import { useGlobalContext } from '../../../context/context';
import successIcon from '../../../assets/modal-success-icon.svg';
import warningIcon from '../../../assets/modal-warning-icon.svg';
import unsuccessfulIcon from '../../../assets/modal-unsuccessful-icon.svg';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PinInput } from '@mantine/core';

// MODAL FOR WHEN USER WANTS TO UPLOAD PAYEMNT RECEIPT
export const DepositModal = ({ via, setShowStatusModal }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { baseURL, setShowDepositModal } = useGlobalContext();
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
      />
    );
  });

  const formik = useFormik({
    initialValues: {
      amount: ``,
      via: via,
      type: ``,
    },
    validationSchema: Yup.object({
      amount: Yup.number(`Numbers only`).required(
        `Please provide deposit amount`
      ),
    }),
    onSubmit() {
      addDeposit();
    },
  });

  const addDeposit = async () => {
    const formData = new FormData();
    formData.append(`amount`, formik.values.amount);
    formData.append(`via`, formik.values.via);
    formData.append(`type`, formik.values.type);
    formData.append(`file`, files[0]);
    try {
      setLoading(true);
      await axios.post(`${baseURL}/deposit/add`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setShowDepositModal(false);
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal deposit-modal'>
        <div className='close-btn'>
          <FaTimes onClick={() => setShowDepositModal(false)} />
        </div>
        <form action='' onSubmit={formik.handleSubmit}>
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={setFiles}
            className='drop-zone'
          >
            <Text align='center'>Drop Image here</Text>
          </Dropzone>

          <SimpleGrid
            cols={1}
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            mt={previews.length > 0 ? 'xl' : 0}
            className='preview'
          >
            {previews}
          </SimpleGrid>
          <div className='form-control'>
            <label
              htmlFor='amount'
              className={
                formik.touched.amount && formik.errors.amount ? `error` : ``
              }
            >
              {formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : `Deposit Amount`}
            </label>
            <input
              type='number'
              id='amount'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className='btn'>
            <button className='blue' type='submit'>
              Submit {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// STATUS MODAL
export const StatusModal = ({
  status,
  message,
  dismissFunction,
  navigationUrl,
}) => {
  const navigate = useNavigate();

  return (
    <div className='modal-container' id='status'>
      <div className='modal status-modal'>
        <div className='icon'>
          {status === `success` ? (
            <img src={successIcon} alt='success' />
          ) : status === `warning` ? (
            <img src={warningIcon} alt='success' />
          ) : status === `unsuccessful` ? (
            <img src={unsuccessfulIcon} alt='success' />
          ) : null}
        </div>
        <p>{message}</p>
        <div className='btn'>
          <button
            className='blue'
            onClick={() => {
              dismissFunction(false);
              navigate(navigationUrl);
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

// SECURE AUTHORIZATION CODE MODAL
export const SecureAuthorizationCodeModal = ({
  setShowOtpModal,
  showOtpModal,
  setOtpSuccess,
  setShowStatusModal,
}) => {
  const handleClose = () => setShowOtpModal(``);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: `600px`,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const [otp, setOtp] = useState(``);
  const [errorMsg, setErrorMsg] = useState(``);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const checkOtp = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/auth/check-otp`,
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowOtpModal(``);
      setOtpSuccess(true);
      setShowStatusModal(true);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkOtp();
  };

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg(``);
      }, 3000);
    }
  }, [errorMsg]);

  return (
    <div>
      <Modal
        open={showOtpModal === `Level 1`}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='secure-authorization-modal'
      >
        <Box sx={style}>
          <div className='close-btn'>
            <FaTimes onClick={handleClose} />
          </div>
          <h2>Secure Authorization Code is required.</h2>
          <p className='warning'>
            The Federal SAC code is required for this transaction can be
            completed successfully. You can visit any of our nearest branches or
            contact our online customer care representative with: for more
            details of the SAC code for this transaction.
          </p>
          <form action='' onSubmit={handleSubmit}>
            <div className='form-control'>
              <label htmlFor='otp'>Enter Authorization Code</label>
              <PinInput
                className='otp-input'
                id='otp'
                inputType='tel'
                inputMode='numeric'
                length={5}
                value={otp}
                onChange={(value) => setOtp(value)}
              />
            </div>
            <p className='disclaimer'>
              We have security measures in place to safeguard your money,
              because we are committed to providing you with a secure banking
              experience.
            </p>

            {errorMsg && <p className='error'>{errorMsg}</p>}
            <div className='btn'>
              <button className='blue'>
                Submit Code
                {loading && <img src={spinner} alt='' />}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

// TRANSFER ACCESS CODE MODAL
export const TransferAccessCodeModal = ({
  setShowOtpModal,
  showOtpModal,
  setOtpSuccess,
  setShowStatusModal,
}) => {
  const handleClose = () => setShowOtpModal(``);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: `600px`,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const [otp, setOtp] = useState(``);
  const [errorMsg, setErrorMsg] = useState(``);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const checkOtp = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/auth/check-otp`,
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowOtpModal(``);
      setOtpSuccess(true);
      setShowStatusModal(true);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkOtp();
  };

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg(``);
      }, 3000);
    }
  }, [errorMsg]);

  return (
    <div>
      <Modal
        open={showOtpModal === `Level 2`}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='secure-authorization-modal'
      >
        <Box sx={style}>
          <div className='close-btn'>
            <FaTimes onClick={handleClose} />
          </div>
          <h2>Transfer Access Code is required.</h2>
          <p className='warning'>
            The Federal TAC code is required for this transaction can be
            completed successfully. You can visit any of our nearest branches or
            contact our online customer care representative with: for more
            details of the TAC code for this transaction.
          </p>

          <form action='' onSubmit={handleSubmit}>
            <div className='form-control'>
              <label htmlFor='otp'>Enter Access Code</label>
              <PinInput
                className='otp-input'
                id='otp'
                inputType='tel'
                inputMode='numeric'
                length={5}
                value={otp}
                onChange={(value) => setOtp(value)}
              />
            </div>
            <p className='disclaimer'>
              We have security measures in place to safeguard your money,
              because we are committed to providing you with a secure banking
              experience.
            </p>

            {errorMsg && <p className='error'>{errorMsg}</p>}
            <div className='btn'>
              <button className='blue'>
                Submit Code
                {loading && <img src={spinner} alt='' />}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

// UPGRADE ACCOUNT MODAL
export const UpgradeAccountModal = ({
  setShowOtpModal,
  showOtpModal,
  setOtpSuccess,
  setShowStatusModal,
}) => {
  const handleClose = () => setShowOtpModal(``);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: `600px`,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const [otp, setOtp] = useState(``);
  const [errorMsg, setErrorMsg] = useState(``);
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const checkOtp = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/auth/check-otp`,
        { otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowOtpModal(``);
      setOtpSuccess(true);
      setShowStatusModal(true);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkOtp();
  };

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg(``);
      }, 3000);
    }
  }, [errorMsg]);

  return (
    <div>
      <Modal
        open={showOtpModal === `Level 3`}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='secure-authorization-modal'
      >
        <Box sx={style}>
          <div className='close-btn'>
            <FaTimes onClick={handleClose} />
          </div>
          <h2>Personal Identification Code is required.</h2>
          <p className='warning'>
            The Federal PIC code is required for this transaction can be
            completed successfully. You can visit any of our nearest branches or
            contact our online customer care representative with: for more
            details of the PIC code for this transaction.
          </p>

          <form action='' onSubmit={handleSubmit}>
            <div className='form-control'>
              <label htmlFor='otp'>Enter Identification Code</label>
              <PinInput
                className='otp-input'
                id='otp'
                inputType='tel'
                inputMode='numeric'
                length={5}
                value={otp}
                onChange={(value) => setOtp(value)}
              />
            </div>
            <p className='disclaimer'>
              We have security measures in place to safeguard your money,
              because we are committed to providing you with a secure banking
              experience.
            </p>

            {errorMsg && <p className='error'>{errorMsg}</p>}
            <div className='btn'>
              <button className='blue'>
                Submit Code
                {loading && <img src={spinner} alt='' />}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
