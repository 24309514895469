import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/dashboard/navbar/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import {
  UpgradeAccountModal,
  SecureAuthorizationCodeModal,
  StatusModal,
  TransferAccessCodeModal,
} from '../../../components/dashboard/modals/Modals';
import { useGlobalContext } from '../../../context/context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import spinner from '../../../assets/rolling-spinner-white.svg';
import axios from 'axios';
import { FaChevronLeft } from 'react-icons/fa';
import BottomTab from '../../../components/dashboard/bottom-tab/BottomTab';

export const Withdraw = () => {
  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const { fetchUpdateUserDetails } = useGlobalContext();

  useEffect(() => {
    fetchUpdateUserDetails(token);
    // eslint-disable-next-line
  }, []);

  return (
    <main className='withdraw-page'>
      <Navbar moblieNavText={`Withdraw Funds`} currentPage={`withdraw`} />
      <BottomTab />

      <div className='page-title'>
        <h3>Withdraw Funds</h3>
      </div>
      <div className='methods'>
        <Link to='/dashboard/withdraw/bank-transfer' className='card'>
          <h3>Bank Transfer</h3>
        </Link>
        <Link to='/dashboard/withdraw/paypal-withdrawal' className='card'>
          <h3>Paypal withdrawal</h3>
        </Link>
        <Link to='/dashboard/withdraw/skrill-withdrawal' className='card'>
          <h3>Skrill withdrawal</h3>
        </Link>
        <Link to='/dashboard/withdraw/crypto-withdrawal' className='card'>
          <h3>Crypto withdrawal</h3>
        </Link>
      </div>
    </main>
  );
};

export const BankWithdrawal = () => {
  const navigate = useNavigate();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
  const { baseURL, fetchUpdateUserDetails } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(0);

  const { otpLevel } = JSON.parse(sessionStorage.getItem(`userInfo`))
    ? JSON.parse(sessionStorage.getItem(`userInfo`))
    : ``;

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;

  const formik = useFormik({
    initialValues: {
      accountNumber: ``,
      accountName: ``,
      bankName: ``,
      address: ``,
      transferType: ``,
      email: ``,
      amount: ``,
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string().required(
        `Account number or IBAN is required`
      ),
      accountName: Yup.string().required(
        `Recipient's account name is required`
      ),
      bankName: Yup.string().required(`Recipient's bank name is required`),
      address: Yup.string().required(`Recipient's address is required`),
      transferType: Yup.string().required(`Transfer Type is required`),
      email: Yup.string()
        .email(`Enter a valid email`)
        .required(`Recipient's email is required`),
      amount: Yup.number().required(`Amount is required`),
    }),
    onSubmit() {
      if (otpLevel === `Level 1`) {
        setShowOtpModal(`Level 1`);
      } else if (otpLevel === `Level 2`) {
        setShowOtpModal(`Level 2`);
      } else if (otpLevel === `Level 3`) {
        setShowOtpModal(`Level 3`);
      } else {
        sendWithdrawalRequest();
      }
    },
  });

  const sendWithdrawalRequest = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/withdrawal/add-bank`, formik.values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setShowStatusModal(true);
      setWithdrawalSuccess(true);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdateUserDetails(token);
    // eslint-disable-next-line
  }, []);

  return (
    <main className='withdraw-page bank'>
      <Navbar moblieNavText={`Crypto Withdrawal`} currentPage={`withdraw`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/withdraw`)} />
        <h3>Bank Transfer</h3>
      </div>

      <div className='withdrawal-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          <div className='form-row'>
            {/* ACCOUNT NUMBER */}
            <div className='form-control'>
              <label
                htmlFor='accountNumber'
                className={
                  formik.touched.accountNumber && formik.errors.accountNumber
                    ? `error`
                    : ``
                }
              >
                {formik.touched.accountNumber && formik.errors.accountNumber
                  ? formik.errors.accountNumber
                  : `Account Number / IBAN`}
              </label>
              <input
                type='text'
                name='accountNumber'
                id='accountNumber'
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ACCOUNT NAME */}
            <div className='form-control'>
              <label
                htmlFor='accountName'
                className={
                  formik.touched.accountName && formik.errors.accountName
                    ? `error`
                    : ``
                }
              >
                {formik.touched.accountName && formik.errors.accountName
                  ? formik.errors.accountName
                  : `Account Name`}
              </label>
              <input
                type='text'
                name='accountName'
                id='accountName'
                value={formik.values.accountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className='form-row'>
            {/* BANK NAME */}
            <div className='form-control'>
              <label
                htmlFor='bankName'
                className={
                  formik.touched.bankName && formik.errors.bankName
                    ? `error`
                    : ``
                }
              >
                {formik.touched.bankName && formik.errors.bankName
                  ? formik.errors.bankName
                  : `Recipient's Bank Name`}
              </label>
              <input
                type='text'
                name='bankName'
                id='bankName'
                value={formik.values.bankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* RECIPIENT ADDRESS */}
            <div className='form-control'>
              <label
                htmlFor='address'
                className={
                  formik.touched.address && formik.errors.address ? `error` : ``
                }
              >
                {formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : `Recipient's Address`}
              </label>
              <input
                type='text'
                name='address'
                id='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          <div className='form-row'>
            {/* TRANSFER TYPE */}
            <div className='form-control'>
              <label
                htmlFor='transferType'
                className={
                  formik.touched.transferType && formik.errors.transferType
                    ? `error`
                    : ``
                }
              >
                {formik.touched.transferType && formik.errors.transferType
                  ? formik.errors.transferType
                  : `Transfer Type`}
              </label>
              <select
                name='transferType'
                id='transferType'
                value={formik.values.transferType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option hidden defaultChecked>
                  Select transfer type
                </option>
                <option value='International Transfer'>
                  International Transfer
                </option>
              </select>
            </div>

            {/* EMAIL ADDRESS */}
            <div className='form-control'>
              <label
                htmlFor='email'
                className={
                  formik.touched.email && formik.errors.email ? `error` : ``
                }
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : `Recipient's Email Address`}
              </label>
              <input
                type='email'
                name='email'
                id='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {/* CRYPTO AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='amount'
              className={
                formik.touched.amount && formik.errors.amount ? `error` : ``
              }
            >
              {formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : `Withdrawal Amount`}
            </label>
            <input
              type='number'
              name='amount'
              id='amount'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button
              className='blue'
              type='submit'
              onClick={() => {
                if (otpLevel === `Level 1`) {
                  setShowOtpModal(1);
                }
              }}
            >
              Send Money {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showStatusModal && withdrawalSuccess ? (
        <StatusModal
          message={`Withdrawal Request successfully sent. Please await approval`}
          status={`success`}
          dismissFunction={setShowStatusModal}
          navigationUrl={`/dashboard/transaction-history`}
        />
      ) : null}
      {showStatusModal && otpSuccess ? (
        <StatusModal
          message={`OTP Valid. Please retry the withdrawal transaction in 5 minute`}
          status={`success`}
          dismissFunction={setShowStatusModal}
          navigationUrl={`/dashboard/overview`}
        />
      ) : null}

      {showOtpModal === `Level 1` ? (
        <SecureAuthorizationCodeModal
          setShowOtpModal={setShowOtpModal}
          showOtpModal={showOtpModal}
          setShowStatusModal={setShowStatusModal}
          setOtpSuccess={setOtpSuccess}
        />
      ) : null}
      {showOtpModal === `Level 2` ? (
        <TransferAccessCodeModal
          setShowOtpModal={setShowOtpModal}
          showOtpModal={showOtpModal}
          setShowStatusModal={setShowStatusModal}
          setOtpSuccess={setOtpSuccess}
        />
      ) : null}
      {showOtpModal === `Level 3` ? (
        <UpgradeAccountModal
          setShowOtpModal={setShowOtpModal}
          showOtpModal={showOtpModal}
          setShowStatusModal={setShowStatusModal}
          setOtpSuccess={setOtpSuccess}
        />
      ) : null}
    </main>
  );
};

export const CryptoWithdrawal = () => {
  const navigate = useNavigate();
  const [showwStatusModal, setShowStatusModal] = useState(false);
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  const { totalBalance } = JSON.parse(sessionStorage.getItem(`userInfo`));

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const formik = useFormik({
    initialValues: {
      cryptoType: ``,
      cryptoNetwork: ``,
      amount: ``,
      cryptoAddress: ``,
    },
    validationSchema: Yup.object({
      cryptoType: Yup.string().required(`Crypto Type is required`),
      cryptoNetwork: Yup.string().required(`Network is required`),
      amount: Yup.number().required(`Crypto Amount is required`),
      cryptoAddress: Yup.string().required(`Crypto Address is required`),
    }),
    onSubmit() {
      sendCryptoWithdrawalRequest();
    },
  });

  const sendCryptoWithdrawalRequest = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/withdrawal/add-crypto`,
        {
          cryptoType: formik.values.cryptoType,
          cryptoNetwork: formik.values.cryptoNetwork,
          amount: Number(formik.values.amount),
          cryptoAddress: formik.values.cryptoAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
      setShowStatusModal(true);
    }
  };

  useEffect(() => {
    if (formik.values.amount > totalBalance) {
      setErrorMsg(`Withdrawal amount cannot exceed total balance`);
    } else {
      setErrorMsg(``);
    }
  }, [formik.values.amount, totalBalance]);

  return (
    <main className='withdraw-page crypto'>
      <Navbar moblieNavText={`Crypto Withdrawal`} currentPage={`withdraw`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/withdraw`)} />
        <h3>Crypto Withdrawal</h3>
      </div>

      <div className='withdrawal-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          {/* CRYPTO TYPE */}
          <div className='form-control'>
            <label
              htmlFor='cryptoType'
              className={
                formik.touched.cryptoType && formik.errors.cryptoType
                  ? `error`
                  : ``
              }
            >
              {formik.touched.cryptoType && formik.errors.cryptoType
                ? formik.errors.cryptoType
                : `Crypto Type`}
            </label>
            <select
              name='cryptoType'
              id='cryptoType'
              value={formik.values.cryptoType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select crypto type
              </option>
              <option value='Bitcoin'>Bitcoin</option>
              <option value='Ethereum'>Ethereum</option>
              <option value='USDT'>USDT</option>
            </select>
          </div>

          {/* CRYPTO ADDRESS */}
          <div className='form-control'>
            <label
              htmlFor='cryptoAddress'
              className={
                formik.touched.cryptoAddress && formik.errors.cryptoAddress
                  ? `error`
                  : ``
              }
            >
              {formik.touched.cryptoAddress && formik.errors.cryptoAddress
                ? formik.errors.cryptoAddress
                : `Crypto Address`}
            </label>
            <input
              type='text'
              name='cryptoAddress'
              id='cryptoAddress'
              value={formik.values.cryptoAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* CRYPTO NETWORK */}
          <div className='form-control'>
            <label
              htmlFor='cryptoNetwork'
              className={
                formik.touched.cryptoNetwork && formik.errors.cryptoNetwork
                  ? `error`
                  : ``
              }
            >
              {formik.touched.cryptoNetwork && formik.errors.cryptoNetwork
                ? formik.errors.cryptoNetwork
                : `Select Network`}
            </label>
            <select
              name='cryptoNetwork'
              id='cryptoNetwork'
              value={formik.values.cryptoNetwork}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden defaultChecked>
                Select Network
              </option>
              {formik.values.cryptoType === `Bitcoin` ? (
                <option value='BTC'>BTC</option>
              ) : formik.values.cryptoType === `Ethereum` ? (
                <option value='ETH'>ETH</option>
              ) : formik.values.cryptoType === `USDT` ? (
                <>
                  <option value='USDT-BSC'>USDT-BSC</option>
                  <option value='USDT-ERC20'>USDT-ERC20</option>
                  <option value='USDT-TRC20'>USDT-TRC20</option>
                </>
              ) : null}
            </select>
          </div>

          {/* CRYPTO AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='amount'
              className={
                formik.touched.amount && formik.errors.amount ? `error` : ``
              }
            >
              {formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : `Crypto Amount`}
            </label>
            <input
              type='number'
              name='amount'
              id='amount'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {errorMsg && <p className='error-msg'>{errorMsg}</p>}

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button className='blue' type='submit'>
              Make Withdrawal {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showwStatusModal ? (
        <StatusModal
          message={`WIthdrawal Request successfully sent. Please await transaction approval`}
          status={`success`}
          dismissFunction={setShowStatusModal}
          navigationUrl={`/dashboard/transaction-history`}
        />
      ) : null}
    </main>
  );
};

export const PaypalWithdrawal = () => {
  const navigate = useNavigate();
  const [showwStatusModal, setShowStatusModal] = useState(false);
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);

  const { totalBalance } = JSON.parse(sessionStorage.getItem(`userInfo`));

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const formik = useFormik({
    initialValues: {
      paypalEmail: ``,
      paypalUsername: ``,
      amount: ``,
    },
    validationSchema: Yup.object({
      paypalEmail: Yup.string()
        .email(`Provide a valid email`)
        .required(`Paypal email is required`),
      amount: Yup.number().required(`Amount is required`),
      paypalUsername: Yup.string().required(`Paypal username is required`),
    }),
    onSubmit() {
      sendWithdrawalRequest();
    },
  });

  const sendWithdrawalRequest = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/withdrawal/add-paypal`,
        {
          paypalEmail: formik.values.paypalEmail,
          amount: Number(formik.values.amount),
          paypalUsername: formik.values.paypalUsername,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
      setShowStatusModal(true);
    }
  };

  useEffect(() => {
    if (formik.values.amount > totalBalance) {
      setErrorMsg(`Withdrawal amount cannot exceed total balance`);
    } else {
      setErrorMsg(``);
    }
  }, [formik.values.amount, totalBalance]);

  return (
    <main className='withdraw-page paypal'>
      <Navbar moblieNavText={`Crypto Withdrawal`} currentPage={`withdraw`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/withdraw`)} />
        <h3>Paypal Withdrawal</h3>
      </div>

      <div className='withdrawal-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          {/* PAYPAL EMAIL */}
          <div className='form-control'>
            <label
              htmlFor='paypalEmail'
              className={
                formik.touched.paypalEmail && formik.errors.paypalEmail
                  ? `error`
                  : ``
              }
            >
              {formik.touched.paypalEmail && formik.errors.paypalEmail
                ? formik.errors.paypalEmail
                : `Paypal Email`}
            </label>
            <input
              name='paypalEmail'
              id='paypalEmail'
              type='email'
              value={formik.values.paypalEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* PAYPAL USERNAME */}
          <div className='form-control'>
            <label
              htmlFor='paypalUsername'
              className={
                formik.touched.paypalUsername && formik.errors.paypalUsername
                  ? `error`
                  : ``
              }
            >
              {formik.touched.paypalUsername && formik.errors.paypalUsername
                ? formik.errors.paypalUsername
                : `Paypal Username`}
            </label>
            <input
              name='paypalUsername'
              id='paypalUsername'
              type='text'
              value={formik.values.paypalUsername}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='amount'
              className={
                formik.touched.amount && formik.errors.amount ? `error` : ``
              }
            >
              {formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : `Amount`}
            </label>
            <input
              type='number'
              name='amount'
              id='amount'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {errorMsg && <p className='error-msg'>{errorMsg}</p>}

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button className='blue' type='submit' disabled={errorMsg && true}>
              Make Withdrawal {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showwStatusModal ? (
        <StatusModal
          message={`WIthdrawal Request successfully sent. Please await transaction approval`}
          status={`success`}
          dismissFunction={setShowStatusModal}
          navigationUrl={`/dashboard/transaction-history`}
        />
      ) : null}
      {showwStatusModal && errorMsg ? (
        <StatusModal
          message={errorMsg}
          status={`unsuccessful`}
          dismissFunction={setShowStatusModal}
        />
      ) : null}
    </main>
  );
};

export const SkrillWithdrawal = () => {
  const navigate = useNavigate();
  const [showwStatusModal, setShowStatusModal] = useState(false);
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(``);
  const { totalBalance } = JSON.parse(sessionStorage.getItem(`userInfo`));

  const token = JSON.parse(sessionStorage.getItem(`userToken`))
    ? JSON.parse(sessionStorage.getItem(`userToken`))
    : ``;
  const formik = useFormik({
    initialValues: {
      skrillEmail: ``,
      skrillUserId: ``,
      amount: ``,
    },
    validationSchema: Yup.object({
      skrillEmail: Yup.string()
        .email(`Provide a valid email`)
        .required(`Skrill email is required`),
      amount: Yup.number().required(`Amount is required`),
      skrillUserId: Yup.string().required(`Skrill user id is required`),
    }),
    onSubmit() {
      sendLoanRequest();
    },
  });

  const sendLoanRequest = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${baseURL}/withdrawal/add-skrill`,
        {
          skrillEmail: formik.values.skrillEmail,
          amount: Number(formik.values.amount),
          skrillUserId: formik.values.skrillUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setShowStatusModal(true);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.response.data.message);
      setShowStatusModal(true);
    }
  };

  useEffect(() => {
    if (formik.values.amount > totalBalance) {
      setErrorMsg(`Withdrawal amount cannot exceed total balance`);
    } else {
      setErrorMsg(``);
    }
  }, [formik.values.amount, totalBalance]);

  return (
    <main className='withdraw-page skrill'>
      <Navbar moblieNavText={`Skrill Withdrawal`} currentPage={`withdraw`} />

      <div className='page-title'>
        <FaChevronLeft onClick={() => navigate(`/dashboard/withdraw`)} />
        <h3>Skrill Withdrawal</h3>
      </div>

      <div className='withdrawal-form'>
        <form action='' onSubmit={formik.handleSubmit}>
          {/* SKRILL EMAIL */}
          <div className='form-control'>
            <label
              htmlFor='skrillEmail'
              className={
                formik.touched.skrillEmail && formik.errors.skrillEmail
                  ? `error`
                  : ``
              }
            >
              {formik.touched.skrillEmail && formik.errors.skrillEmail
                ? formik.errors.skrillEmail
                : `Skrill Email`}
            </label>
            <input
              name='skrillEmail'
              id='skrillEmail'
              type='email'
              value={formik.values.skrillEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* SKRILL USER ID */}
          <div className='form-control'>
            <label
              htmlFor='skrillUserId'
              className={
                formik.touched.skrillUserId && formik.errors.skrillUserId
                  ? `error`
                  : ``
              }
            >
              {formik.touched.skrillUserId && formik.errors.skrillUserId
                ? formik.errors.skrillUserId
                : `Skrill User Id`}
            </label>
            <input
              name='skrillUserId'
              id='skrillUserId'
              type='text'
              value={formik.values.skrillUserId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* AMOUNT */}
          <div className='form-control'>
            <label
              htmlFor='amount'
              className={
                formik.touched.amount && formik.errors.amount ? `error` : ``
              }
            >
              {formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : `Amount`}
            </label>
            <input
              type='number'
              name='amount'
              id='amount'
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {errorMsg && <p className='error-msg'>{errorMsg}</p>}

          {/* SUBMIT BTN */}
          <div className='btn'>
            <button className='blue' type='submit'>
              Make Withdrawal {loading && <img src={spinner} alt='' />}
            </button>
          </div>
        </form>
      </div>
      {showwStatusModal ? (
        <StatusModal
          message={`WIthdrawal Request successfully sent. Please await transaction approval`}
          status={`success`}
          dismissFunction={setShowStatusModal}
          navigationUrl={`/dashboard/transaction-history`}
        />
      ) : null}
      {showwStatusModal && errorMsg ? (
        <StatusModal
          message={errorMsg}
          status={`unsuccessful`}
          dismissFunction={setShowStatusModal}
        />
      ) : null}
    </main>
  );
};
